<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('bank_account.title') }}</h1>
                </div>
            </div>
            <div class="card">
                <div class="card-header">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_card_no_my' : 'bank_account.label_bank_account') }}</div>

                <!-- loading indicator -->
                <div v-if="loading" class="card-body">
                    <loading-indicator />
                </div>
                <div v-else class="card-body">
                    <!-- loading error -->
                    <div v-if="error" class="alert alert-danger">{{ $t('bank_account.load_error') }}</div>

                    <!-- loaded -->
                    <form v-else method="post" action="javascript:void(0)" v-on:submit="toggleConfirm">
                        <div class="form-group" v-if="sysconfig.region === 'de'">
                            <div class="row">
                                <div class="col required">
                                    <span class="symbol">{{"*"}}</span><label class="form-label">{{ $t(sysconfig.region === 'de'? 'bank_account.cardholder_name' : 'bank_account.client_name') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="bank_card_holder" v-model="bank_card_holder" class="form-control" data-val="true" data-val-required="*" />
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="sysconfig.region !== 'de'">
                            <div class="form-group col-sm-6">
                                <div class="col">
                                    <span class="au-symbol">{{"*"}}</span><label class="form-label">{{ $t('idverification.label_name') }}</label>
                                    <input type="text" v-model="FirstName" name="FirstName" maxlength="128" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                                    <label class="form-label consistent">{{ $t('idverification.label_document') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="sysconfig.region !== 'de'">
                            <div class="form-group col-sm-6">
                                <div class="col">
                                    <label class="form-label">{{ $t('idverification.middle_name')+'-'+$t('idverification.if_have') }}</label>
                                    <input type="text" name="MiddleName" v-model="MiddleName"  maxlength="128" class="form-control" autocomplete="off" data-val="true" :data-val-required="false" />
                                    <label class="form-label consistent">{{ $t('idverification.label_document') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="sysconfig.region !== 'de'">
                            <div class="form-group col-sm-6">
                                <div class="col">
                                    <span class="au-symbol">{{"*"}}</span><label class="form-label">{{ $t('idverification.last_name') }}</label>
                                    <input type="text" name="Surname" v-model="Surname" maxlength="128" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                                    <label class="form-label consistent">{{ $t('idverification.label_document') }}</label>
                                </div>
                            </div>
                        </div>
                        <!-- No need to support id number now -->
                        <!-- <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_id_number_my' : 'bank_account.label_id_number') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="id_number" v-model="id_number" class="form-control" maxlength="18" data-val="true" data-val-required="*" data-val-maxlength="*" data-val-maxlength-max="18" data-val-minlength="*" data-val-minlength-min="4" />
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <span class="au-symbol" v-if="sysconfig.region === 'de'">{{"*"}}</span><label class="form-label">{{ $t('bank_account.bank_name') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input v-if="!sysconfig.supportedBanks || sysconfig.supportedBanks.length === 0" type="text" name="bank" v-model="bank" class="form-control" data-val="true" />
                                    <select v-else name="bank" v-model="bank" class="form-control" data-val="true">
                                        <option value="">{{ $t('bank_account.label_select_bank') }}</option>
                                        <option value="" disabled>-----------------</option>
                                        <option v-for="bank in sysconfig.supportedBanks" :key="bank">{{ bank }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                         <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{$t(sysconfig.region === 'de'? 'bank_account.customer_address' : 'bank_account.customer_address') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="Address" v-model="registered_bank_address" class="form-control" data-val="true" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col required">
                                    <span class="symbol" v-if="sysconfig.region === 'de'" >{{"*"}}</span><label class="form-label">{{ $t(sysconfig.region === 'de'? 'bank_account.bank_Account' : 'bank_account.customer_number') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="alert alert-warning shadow-sm text-lg" v-if="formatted_card_no">
                                        <b>{{ formatted_card_no }}</b>
                                    </div>
                                    <input type="text" name="card_no" v-model="card_no" maxlength="128" pattern="\d*" class="form-control" data-val="true" data-val-regex="*" data-val-regex-pattern=".*" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{$t(sysconfig.region === 'de'?'bank_account.european_bank_number':"bank_account.australian_number") }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="BSB" v-model="BSB_number" class="form-control" data-val="true" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t('bank_account.international_number') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="SwiftCode" v-model="swift_code" class="form-control" data-val="true"/>
                                </div>
                            </div>
                        </div>
                        <!-- Branch isn't required by the HK region. -->
                        <!-- <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t('bank_account.label_branch') }}
                                        <span v-if="sysconfig.is_hk">({{ $t('general.optional') }})</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input v-if="sysconfig.is_hk" type="text" name="branch" v-model="branch" class="form-control" :placeholder="$t('general.optional')" />
                                    <input v-else type="text" name="branch" v-model="branch" class="form-control" data-val="true" data-val-required="*" />
                                </div>
                            </div>
                        </div> -->

                        <!-- do we need sms verification -->
                        <!-- <div class="form-group" v-if="requires_sms_verification">
                            <sms-verification ref="smsComp" action="send" useAccountKey="true" />
                            <div class="row">
                                <div class="col">
                                    <div class="help-block">为了您的资金安全，更新银行卡信息需要短信验证码确认。</div>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-6">
                                    <button class="btn btn-primary" type="submit">{{ $t('general.submit') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal" id="bank-modal" ref="confirmModal">
            <form method="post" action="javascript:void(0)" @submit="updateFunc">
                <input type="hidden" name="Uid" v-model="id" />
                <input type="hidden" name="IDNumber" v-model="id_number" />
                <input type="hidden" name="BankCardHolder" v-if="sysconfig.region === 'de'" v-model="bank_card_holder" />
                <input type="hidden" name="FirstName" v-model="FirstName"  v-if="sysconfig.region !== 'de'" />
                <input type="hidden" name="MiddleName" v-model="MiddleName" v-if="sysconfig.region !== 'de'" />
                <input type="hidden" name="Surname" v-model="Surname" v-if="sysconfig.region !== 'de'" />
                <input type="hidden" name="Address" v-model="registered_bank_address" />
                <input type="hidden" name="BSB" v-model="BSB_number" />
                <input type="hidden" name="Bank" v-model="bank" />
                <input type="hidden" name="SwiftCode" v-model="swift_code" />
                <input type="hidden" name="Branch" v-model="branch" />
                <input type="hidden" name="BankCardNo" v-model="card_no" />

                <div class="modal-dialog" role="document" id="cardModel">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('bank_account.label_confirm') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-warning">{{ $t('bank_account.label_confirm_desp') }}</div>
                            <div class="form-group row" v-if="sysconfig.region === 'de'">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_account_name_my' : 'bank_account.label_account_name') }}</div>
                                <div class="col-8">{{ bank_card_holder }}</div>
                            </div>
                            <div class="form-group row" v-if="sysconfig.region !== 'de'">
                                <div class="col-4 text-right text-muted">{{ $t('idverification.label_name') }}</div>
                                <div class="col-8">{{ FirstName }}</div>
                            </div>
                             <div class="form-group row" v-if="sysconfig.region !== 'de'">
                                <div class="col-4 text-right text-muted">{{ $t('idverification.middle_name') }}</div>
                                <div class="col-8">{{ MiddleName }}</div>
                            </div>
                             <div class="form-group row" v-if="sysconfig.region !== 'de'">
                                <div class="col-4 text-right text-muted">{{ $t('idverification.last_name') }}</div>
                                <div class="col-8">{{ Surname }}</div>
                            </div>
                            <!-- Skip id number for now -->
                            <!-- <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_id_number_my' : 'bank_account.label_id_number') }}</div>
                                <div class="col-8">{{ id_number }}</div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_bank_my' : 'bank_account.label_bank') }}</div>
                                <div class="col-8">{{ bank }} {{ branch }}</div>
                            </div>

                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_card_no_my' : 'bank_account.label_card_no') }}</div>
                                <div class="col-8 text-warning">
                                    <b>{{ formatted_card_no }}</b>
                                </div>
                            </div>
                             <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.region === 'de'? 'bank_account.customer_address' : 'bank_account.customer_address') }}</div>
                                <div class="col-8">{{ registered_bank_address }}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.region === 'de'?'bank_account.european_bank_number':"bank_account.australian_number") }}</div>
                                <div class="col-8">{{ BSB_number }}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t('bank_account.international_number') }}</div>
                                <div class="col-8">{{ swift_code }}</div>
                            </div>

                            <!-- 
                                skip sms verification for now as requested.
                            -->
                            <!-- <div class="card">
                                <div class="card-header">{{ $t('vcode.label_account_verfication') }}</div>
                                <div class="card-body">
                                    <sms-verification-component useAccountKey="true" action="send" />
                                </div>
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button v-if="updating" type="submit" class="btn btn-primary btn-loading" disabled>{{ $t('general.confirm') }}</button>
                            <button v-else type="submit" class="btn btn-primary">{{ $t('general.confirm') }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal" ref="deleteModal">
            <form method="post" action="javascript:void(0)" @submit="deleteFunc">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('bank_account.label_delete_title') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-warning">{{ $t('bank_account.label_delete_desp') }}</div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button type="submit" class="btn btn-primary" :class="{ 'btn-loading': updating }" :disabled="updating">{{ $t('general.confirm') }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>
<script>
// import { getConfigAsync } from 'utilities/helper';
import SmsVerificationComponent from '../Components/SmsVerification.vue';
import { Modal } from 'bootstrap';

export default {
    props: ['id'],
    components: { SmsVerificationComponent },

    data() {
        return {
            FirstName: null, 
            MiddleName: null, 
            Surname: null,
            // page state
            loading: true,
            error: false,

            // bank account information
            id_number: null,
            bank_card_holder: null,
            bank: null,
            branch: null,
            card_no: null,
            vcode: null,
            BSB_number:null,
            swift_code:null,
            registered_bank_address:null,

            updating: false,
            requires_sms_verification: false,

            // The popup confirmation modal.
            confirm_modal: null,
            delete_modal: null
        };
    },

    created() {
        // Read current bank account
        this.initAsync();
    },

    computed: {
        // returns the formatted card no.
        formatted_card_no: function () {
            let t = $.trim(this.card_no);
            if (t && t.length) {
                t = t.replace(/\s+/, '');
                const arr = [];
                for (let i = 0; i < t.length; i += 4) {
                    if (i + 4 > t.length) {
                        arr.push(t.substr(i));
                    } else {
                        arr.push(t.substr(i, 4));
                    }
                }
                return arr.join(' ');
            }
            return '';
        }
    },

    mounted() {
        this.confirm_modal = new Modal(this.$refs.confirmModal);
        this.delete_modal = new Modal(this.$refs.deleteModal);
    },

    beforeDestroy() {
        let m = this.confirm_modal;
        if (m) {
            m.dispose();
        }
        m = this.delete_modal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;
            const uid = this.id * 1;
            if (!isNaN(uid) && uid > 0) {
                try {
                    // get current bank account
                    const json = await $.callGetApi(this, `/api/v1/bankaccount/query?id=${uid}`);
                    if (json && json.errcode === 0) {
                        const data = json.data;
                        self.bank = data.bank;
                        self.branch = data.branch;
                        self.bank_card_holder = data.bankCardHolder;
                        self.card_no = data.bankCardNo;
                        self.id_number = data.idNumber;
                        self.BSB_number = data.bsb;
                        self.swift_code = data.swiftCode;
                        self.registered_bank_address = data.address;
                        if(self.sysconfig.region !== 'de'){
                            let nameList=data.bankCardHolder.split("/");
                            self.FirstName = nameList[0];
                            self.MiddleName = nameList[1];
                            self.Surname = nameList[2];
                        }
                        // read system config
                        // const config = await getConfigAsync();

                        // TODO:
                        // Do not support sms verification for now.
                        // self.requires_sms_verification = !!data.bankCardNo && config.bankAccountVCodeRequired;
                        self.requires_sms_verification = false;

                        self.loading = false;

                        Vue.nextTick(() => {
                            $.resetValidators();
                        });
                    }
                } catch (err) {
                    console.error(`ERROR: ${err}`);
                    self.error = true;
                }
            } else {
                self.loading = false;

                Vue.nextTick(() => {
                    $.resetValidators();
                });
            }
        },

        toggleConfirm: function (e) {
            const frm = $(e.target);
            if (frm.valid()) {
                // display a confirmation dialog in 250ms
                this.updating = false;

                if (this.requires_sms_verification) {
                    this.vcode = this.$refs.smsComp.getSmsCode();
                }
                // $('#bank-modal').modal({ dismissible: false }).modal('show');
                this.confirm_modal.show();
            }
        },

        updateFunc: function (e) {
            const frm = $(e.target);

            if (frm.valid()) {
                let data = frm.serializeAsJson();
                 if(this.sysconfig.region !== 'de'){
                    data.bankCardHolder=data.FirstName+"/"+data.MiddleName+"/"+data.Surname;
                    delete data.FirstName; 
                    delete data.MiddleName; 
                    delete data.Surname; 
                 }
                this.callBankAccountApi(g_server_root + '/api/v1/bankaccount', data);
            }
        },

        toggleDelete: function () {
            this.delete_modal.show();
        },

        deleteFunc: function (e) {
            const uid = this.id * 1;
            if (!isNaN(uid) && uid > 0) {
                this.callBankAccountApi(g_server_root + '/api/v1/bankaccount/delete?id=' + uid, null);
            }
        },

        callBankAccountApi: function (url, frmData) {
            const self = this;

            self.updating = true;
            this.$http
                .post(url, frmData)
                .then((resp) => {
                    const json = resp.data;

                    if (json.errcode === 0) {
                        let callback = function () {
                            self.updating = false;
                            // $('#bank-modal').modal('hide');
                            self.confirm_modal.hide();
                            self.delete_modal.hide();

                            $.top_alert(self.$t('general.operation_succeeded'));
                            if(self.sysconfig.region === 'de'){
                                self.$router.push('/user/bankaccounts');
                            }else{
                                self.$router.push('/user/bankaccounts');
                            }                     
                        };
                        setTimeout(callback, 1000);
                    } else {
                        self.updating = false;
                        $.top_error(json.errmsg || self.$t('general.operation_error'));
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ' + err);
                    $.top_error(self.$t('general.operation_error'));
                    self.updating = false;
                });
        }
    }
};
</script>
<style scoped>
.consistent{
    font-size: 10px;
}
.settings-page{
    background: #f9f9f9;
}
.container h1{
    color: #000;
}
.card-header{
    background: #efefef;
}
.card-body{
    background: #fff;
}
.settings-page .card-body .form-group .row{
    color: #000;
}
.btn-primary{
    background: #006CFF;
}
.modal-content{
    background: #f9f9f9;
}
.modal-header,.close {
    color: #000;
}
.btn-default,.modal-content .row{
    color: #000;
}
.btn-secondary{
    background: #fff;
    color: #000;
    height: 31px;
    margin-top: 4px;
}
.required .symbol{
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 2px;
    color: red;
}
.required{
    display: flex
}
.au-symbol{
   color: red; 
}
.form-group .col-8 {
    word-break: break-all;
}
</style>