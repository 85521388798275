<template>
    <section class="page startup-page">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : (sysconfig.region === 'de'?'general.startup':'general.startup_new'))}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <ul class="page-top-nav mb-3">
                <!-- <li>
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li> -->
                <li>
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li class="active">
                    <a href="javascript:;">{{ $t('startup.title_orders') }}</a>
                </li>
            </ul>

            <table class="table compact">
                <thead>
                    <tr>
                        <th>{{ $t('startup.orders_token') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_start_time') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_end_time') }}</th>
                        <th>{{ $t('startup.label_offer_price') }}</th>
                        <th>{{ $t('startup.orders_num_subscribed') }}</th>
                        <th>{{ $t('startup.orders_num_granted') }}</th>
                        <th>{{ $t('startup.orders_time') }}</th>
                    </tr>
                </thead>
                <tbody v-if="orders">
                    <tr v-for="order in orders" :key="order.uid">
                        <td>
                            <div class="d-flex align-items-center">
                                <div v-if="order.svgIcon" class="pe-2">
                                    <startup-icon :token="order" />
                                </div>
                                <div>{{ order.token }}</div>
                            </div>
                        </td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.startTime).formatDateTime() }}</td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.endTime).formatDateTime() }}</td>
                        <td>{{ order.offerPrice }}</td>
                        <td>
                            {{ order.numSubscribed }}
                            <span v-if="order.status === 1" class="badge bg-primary">
                                {{ $t('startup.status_subscribed') }}
                            </span>
                            <span v-else-if="order.status === 2" class="badge bg-primary">
                                {{ $t('startup.status_processing') }}
                            </span>
                            <span v-else-if="order.status === 3" class="small">
                                {{ $t('startup.status_granted') }}
                            </span>
                            <span v-else class="small">
                                {{ $t('startup.status_other') }}
                            </span>
                        </td>
                        <td>{{ order.status === 3 ? order.numGranted : '--' }}</td>
                        <td>
                            <span class="d-none d-md-block">{{ new Date(order.timeCreated).formatDateTime() }}</span>
                            <span class="d-block d-md-none">{{ new Date(order.timeCreated).formatDate() }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-lg-none d-md-none">
                <div class="sheet"  v-for="order in orders" :key="order.uid">
                    <div class="currency"> 
                            <div class="d-flex align-items-center">
                                {{ $t('startup.orders_token') }}
                            </div>
                            <div >
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div v-if="order.svgIcon" class="pe-2">
                                            <startup-icon :token="order" />
                                        </div>
                                        <div class="token">{{ order.token }}</div>
                                    </div>
                                </td>
                            </div>
                    </div>
                    <div class="price"><div>{{ $t('startup.label_offer_price') }}</div><div>{{ order.offerPrice }}</div></div>
                    <div class="number">
                        <div>{{ $t('startup.orders_num_subscribed') }}</div>
                        <div>
                            <td>
                                {{ order.numSubscribed }}
                                <span v-if="order.status === 1" class="badge bg-primary">
                                    {{ $t('startup.status_subscribed') }}
                                </span>
                                <span v-else-if="order.status === 2" class="badge bg-primary">
                                    {{ $t('startup.status_processing') }}
                                </span>
                                <span v-else-if="order.status === 3" class="small">
                                    {{ $t('startup.status_granted') }}
                                </span>
                                <span v-else class="small">
                                    {{ $t('startup.status_other') }}
                                </span>
                           </td>
                        </div>
                    </div>
                    <div class="schedule"><div>{{ $t('startup.orders_num_granted') }}</div>
                        <div > 
                         {{ order.status === 3 ? order.numGranted : '--' }}
                        </div>
                    </div>
                    <div class="end-time"><div>{{ $t('startup.orders_end_time') }}</div><div>{{ new Date(order.endTime).formatDateTime() }}</div></div>
                </div>
            </div>
            <loading-indicator v-if="!orders" />
            <div v-else-if="orders.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </section>
    </section>
</template>

<script>
import StartupIcon from '../Components/_StartupIcon.vue';

export default {
    components: { StartupIcon },
    data() {
        return { orders: null };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/startuporder');
            if (json && json.errcode === 0) {
                this.orders = json.data;
            }
        }
    }
};
</script>
<style scoped>
.startup-page{
    background: #fff;
}
.page-top-nav{
    border-bottom: none;
}
.page-top-nav li.active a {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #242428;
}
.page-top-nav li a {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #969699;
}
.compact thead th{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.compact tbody td{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
}
.table > :not(caption) > * > *{
    background: #f3f3f3;
}
.compact .btn-primary{
    background: #006CFF;
    border-radius: 2px;
}
.compact .small{
   color: #969699;
}
.no-data-indicator{
    color: #666;
    background: url("@/assets/coins/png/huobi/icon-empty.png") no-repeat top;
    background-size: auto 6rem;
}
@media (max-width: 768px){
    .sheet{
        margin-bottom: 20px;
    }
    .compact{
        display: none;
    }
    .sheet >div{
        display:flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .sheet div div:nth-child(2){
        margin-left: auto;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #000922;
    }
    .currency a{
        border: none;
        background: #f3f3f3;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #006CFF;
        text-align: right;
        padding-right: 0px;
        font-size: 16px;
    }
    .schedule .circle{
        height: 18px;
    }
    .sheet div div:nth-child(1){
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #7F8490;
    }
    .sheet div div .token{
        font-size: 14px;
        color: #000922;
    }
     .sheet div div .small{
        font-size: 14px;
        color: #7F8490;
    }
     .page-top-nav li{
        background: #E7E7E7;
        border-radius: 2px;
        margin-right: 10px;
    }
    .page-top-nav li a{
       font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000922;
    }
    .page-top-nav li.active {
        border-bottom: none;
        background: rgba(2,115,228,0.4);
        border-radius: 2px;
    }
     .page-top-nav li.active a{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #006CFF;
     }
}
</style>