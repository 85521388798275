<template>
    <div class="row">
        <div class="col balance-list">
            <div class="total">
                <div class="actions"></div>
            </div>

            <dl class="title-row">
                <dt>
                    <div class="hidden-sm">{{ $t('assets.account_type') }}</div>
                    <div>{{ $t('assets.balance') }}</div>
                    <div>{{ $t('assets.available_balance') }}</div>
                    <div>{{ $t('assets.frozen') }}</div>
                    <div class="hidden-sm"><!-- deposit/withdraw actions --></div>
                </dt>
            </dl>

            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="balance in balance_list" class="currency-row" :key="balance.currency">
                <dd>
                    <div>{{ getTokenName(balance) }}</div>
                    <div>{{ balance.balance }}</div>
                    <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div v-else>{{ balance.available }}</div>
                    <div>{{ balance.frozen }}</div>
                    <div class="actions isActions" :class="{'isActions':isDepositSupported(balance.currency)}">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <span class="line"></span>
                            <router-link :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{ $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <span class="line"></span>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>

                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency) && deposit_currency === balance.currency" class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" />
                </div>
            </dl>

        </div>
    </div>
</template>

<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';

export default {
    components: { DepositForm },
    props: ['balance_list', 'futures_assets', 'bcConfig'],

    data() {
        return {
            coinMap: {},

            // The active currency for deposits
            deposit_currency: ''
        }
    },

    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
    },

    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        }
    }
}
</script>
<style scoped>
.title-row div{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #969699;
}
.currency-row div{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #242428;
}
.currency-row,.title-row{
    border-bottom: none;
}
.currency-row .isActions a{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #006CFF;
    background: #fff;
}
.currency-row a:nth-child(2){
    margin-left: 2px;
}
.currency-row a:nth-child(1){
    margin-right: 2px;
}
.line{
    display: inline-block;
    border: 1px solid #EDEEEF;
    height: 16px;
    line-height: 10px;
}
.currency-row .isActions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}
.balance-list .deposit-address-block {
    background: #f9f9f9;
}
@media (max-width: 768px) {
    .balance-list dl.currency-row dd > div.isActions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
    }
}
</style>