<template>
    <form method="post" action="javascript:void(0)" @submit="submitChange">
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label for="Password"> {{ $t('withdraw_password.label_new_password') }} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="password-box">
                        <input v-model="password" name="WithdrawPassword" maxlength="6" autocomplete="new-password" :type="showPassword?'text':'password'" class="form-control" pattern="\d*" inputmode="decimal" data-val="true" data-val-required="*" data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" />
                        <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                    <div class="help-block">{{ $t('withdraw_password.label_new_password_desp') }}</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col confirm_password">
                    <label for="ConfirmPassword">{{ $t('withdraw_password.label_confirm_password') }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="password-box">
                        <input v-model="confirmPassword" name="ConfirmWithdrawPassword" maxlength="6" autocomplete="new-password" :type="showConfirmPassword?'text':'password'" class="form-control" data-val="true" data-val-required="*" data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" data-val-equalto-other="*.WithdrawPassword" data-val-equalto="*" />
                        <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- sms verification -->
        <sms-verification-component useAccountKey="true" action="send" :emailVCode="emailVCode" />

        <div class="form-group">
            <div class="row">
                <div class="col-md-6">
                    <button type="submit" class="btn-submit btn-block" :class="{ 'btn-loading': submitting }" :disabled="submitting">
                        {{ $t('general.submit') }}
                    </button>
                    <a v-if="allow_skip" href="javascript:;" class="btn btn-link" @click="skipChange">{{ $t('withdraw_password.label_skip') }}</a>
                </div>
            </div>
        </div>
    </form>
</template>
<style scoped>
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
</style>
<script>
import SmsVerificationComponent from '../../Components/SmsVerification.vue';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { SmsVerificationComponent },

    props: ['profile'],

    data() {
        return {
            submitting: false,
            allow_skip: false,

            // Indicates whether to use email or sms verification code.
            emailVCode: false,
            password:'',
            confirmPassword:'',
            showPassword:false,
            showConfirmPassword:false
        };
    },

    mounted() {
        this.allow_skip = this.$route.query.skip === 'true';
        this.emailVCode = this.profile.useEmailVCode;

        $.resetValidators();
    },

    methods: {
        submitChange: function (e) {
            const self = this;
            if(self.password !== self.confirmPassword) {
                $.top_error(self.$t('withdraw_password.err_different_passwords'));
                return
            }

            var frm = $(e.target);
            if (frm.valid()) {
                // post the server
                this.submitting = true;
                this.$http
                    .post(g_server_root + '/api/v1/account/changewithdrawpassword', frm.serialize())
                    .then((resp) => {
                        const json = resp.data;
                        if (json && json.errcode == 0) {
                            $.top_alert(self.$t('general.operation_succeeded'));

                            if (self.allow_skip) {
                                setTimeout(function () {
                                    self.$router.push('/user');
                                }, 500);
                            } else {
                                frm.trigger('reset');
                            }
                        } else {
                            $.top_error(json.errmsg || self.$t('general.operation_error'));
                        }
                    })
                    .catch((err) => {
                        console.error('ERROR: ' + err);
                        $.top_error(self.$t('general.operation_error'));
                    })
                    .then(() => {
                        this.submitting = false;
                    });
            }
        },

        skipChange: function () {
            this.$router.push('/user');
        }
    }
}
</script>
<style scoped>
.settings-page .card-body .form-group , .settings-page .card-body .form-group .row {
    color: #000;
}
</style>