<template>
    <section class="page pro_layout">
        <div class="login container">
            <div v-if="loading">
                <div class="d-flex justify-content-center loader">
                    <div class="spinner-border m-5" style="width: 4rem; height: 4rem">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div v-else class="login-inner mt-5">
                <!-- Any errors? -->
                <div v-if="error" class="alert alert-danger">
                    <div class="row form-group">
                        <div class="col">{{ $t('signout.failed') }}</div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <a v-on:click="signout">{{ $t('signout.title') }}</a>
                        </div>
                    </div>
                </div>

                <!-- Everything is good! -->
                <div v-else class="alert alert-info">
                    <div class="row form-group">
                        <div class="col">{{ $t('signout.succeeded') }}</div>
                    </div>
                    <div class="row form-group">
                        <div class="col">
                            <router-link to="/user/login">{{ $t('signout.label_login') }}</router-link>
                            |
                            <router-link to="/">{{ $t('signout.label_goto_home') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { clearProfile } from 'utilities/helper';
export default {
    data() {
        return {
            loading: true,
            error: false
        };
    },

    created() {
        this.signout();
    },

    methods: {
        signout: function () {
            const self = this;
            self.loading = true;

            setTimeout(() => {
                // Calling signout API to clear authentication cookie.
                self.$http
                    .post(g_server_root + '/api/v1/account/signout')
                    .then(() => {
                        // Already signed out.
                        // Clear authenticated profile.
                        clearProfile();

                        self.error = false;
                    })
                    .catch((err) => {
                        console.error(`ERROR: ${err}`);
                        self.error = true;
                    })
                    .then(() => {
                        self.loading = false;
                    });
            }, 200);
        }
    }
};
</script>
<style scoped>
.pro_layout .login .alert-info .form-group{
    color: #000;
}
.pro_layout .login .alert-info .form-group a{
    color: #000;
}
</style>