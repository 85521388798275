<template>
    <div class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('balance.history_title') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <history-component />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import HistoryComponent from './Components/History.vue';
export default {
    components: { HistoryComponent }
};
</script>
<style scoped>
.settings-page{
    background: #fff;
}
.container h1{
    color: #000;
}
</style>