<template>
    <div class="bottom-nav row d-md-none d-lg-none">
        <div class="col" @click="goto('/')" :class="{ active: active_tab === '/' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#v4.2-home" />
            </svg>
            {{ $t('home.title') }}
        </div>
        <div class="col" @click="goto('/quotes')" :class="{ active: active_tab === 'quotes' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#v4.2-quotes" />
            </svg>
            {{ $t('home.footer_quotes') }}
        </div>
        <div class="col" @click="goto('/futures')" :class="{ active: active_tab === 'trade' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#v4.2-trade" />
            </svg>
            {{ $t('general.trade') }}
        </div>
        <!-- <div class="col" @click="goto('/startup')" :class="{ active: active_tab === 'startup' }">
            <svg class="svg-icon" viewBox="0 0 256 256"><use xlink:href="/dist/svg/icons.svg#icon-rocket" /></svg>
            {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
        </div> -->
        <!-- <div class="col" @click="goto('/user/balance')" :class="{ active: active_tab === 'balance' }"><i class="fas fa-wallet"></i> {{ $t('home.footer_assets') }}</div> -->
        <div class="col" @click="goto('/user/balance')" :class="{ active: active_tab === 'assets' }">
            <!-- <svg class="svg-icon" viewBox="0 0 256 256"><use xlink:href="/dist/svg/icons.svg#icon-wallet" /></svg> -->
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
            </svg>
            {{ $t('assets.assets_property') }}
        </div>
        <div class="col" @click="goto('/user')" :class="{ active: active_tab === 'user' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#v4.2-user" />
            </svg>
            {{ $t('home.footer_personal_settings') }}
        </div>
    </div>
</template>
<style scoped>
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .d-md-none  {
        display: flex !important;
    }
}
</style>
<script>
import '@/assets/svg/v4.2/v4.2-home.svg';
import '@/assets/svg/v4.2/v4.2-quotes.svg';
import '@/assets/svg/v4.2/v4.2-trade.svg';
import '@/assets/svg/v4.2/v4.2-user.svg';
import '@/assets/svg/v4.2/v4.2-assets.svg';

export default {
    props: ['active_tab'],

    methods: {
        goto: function (url) {
            if (this.$route.path != url) {
                this.$router.push({ path: url });
            }
        }
    }
};
</script>