<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('idverification.title') }}</h1>
                </div>
            </div>

            <div class="card">
                <div class="card-header">{{ $t('idverification.upload') }}</div>

                <!-- loading indicator -->
                <div v-if="!profile" class="card-body">
                    <loading-indicator />
                </div>

                <div v-else class="card-body">
                    <div v-if="profile.verifiedLevel >= profile.requiredIDLevel" class="row">
                        <div class="col">
                            <div class="alert alert-info mt-3">{{ $t('user.label_id_verified') }}</div>
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="status === VerificationStatus.None || status === VerificationStatus.Rejected">
                            <div v-if="status !== VerificationStatus.None" class="alert alert-info">{{ $t('idverification.resubmit_desp') }}</div>

                            <UploadIDComponent :profile="profile" @submitted="onSubmitted" />
                        </div>
                        <div v-else>
                            <div class="pt-5 pb-5 inreview-desp">{{ $t('idverification.inreview_desp') }}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import UploadIDComponent from './Components/UploadID.vue';

export default {
    components: { UploadIDComponent },

    data() {
        return {
            profile: null,
            status: -1,

            VerificationStatus: Object.freeze({
                None: 0,
                Created: 1,
                Reviewing: 2,
                Rejected: 3,
                Approved: 4
            })
        };
    },

    created() {
        this.getStateAsync();
    },

    methods: {
        getStateAsync: async function () {
            const self = this;

            ///////////////////////////////////////////////////////////////////////////////////////
            // Read user profile
            const profileJson = await $.callPostApi(self, '/api/v1/account/profile');
            if (profileJson && profileJson.data) {
                ///////////////////////////////////////////////////////////////////////////////////
                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');

                    self.profile = Object.freeze(profileJson.data);
                    self.status = resp.data;
                }
            }
        },

        onSubmitted: function () {
            this.status = this.VerificationStatus.Reviewing;
        }
    }
};
</script>
<style scoped>
    .settings-page {
        background: #f9f9f9;
    }
    .inreview-desp{
        color: #000;
    }
    .container h1{
        color: #000;
    }
    .settings-page .card-header {
        background: #efefef;
    }
    .card-body{
        background: #fff;
    }
   .card-body .alert-info{
        color: #000;
   }
</style>