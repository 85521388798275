<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <!-- <div class="login-page-left">Left component</div> -->
            <div class="login-page-right login-container">
                <div>
                    <div class="img">
                        <p class="title">{{ $t('intro.any_platform') }}</p>
                        <p class="title">{{ $t('intro.listing') }}</p>
                        <img src="@/assets/coins/png/huobi/huoBi@2x.png" alt="" />
                    </div>
                    <div class="login-box">
                        <div class="login container ">
                            <div class="login-inner" id="login-page">
                                <div class="row">
                                    <div class="col">
                                        <h1>{{ $t('login.title') }}</h1>
                                    </div>
                                </div>
                                <login-form-component v-if="regions" v-bind:regions="regions" v-bind:csrfToken="csrfToken" />
                                <loading-indicator v-else />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="less">
.login-left-right {
    margin: auto;
    width: 100%;
}
.login-container{
    background: #fff;
}
.img{
    height: 100%;
}
.login-container .img .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 26px;
    color: #3D3D3D;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;
}
.img .title:nth-child(2){
    color: #007AFF;
}
.login-page .login h1{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #3D3D3D;  
    text-align: start;
    padding-top: 0;
    padding-bottom: 4rem;
}
.login-container > div img{
    width: 400px;
    height: 400px;
    margin: 0;
}
.img ,.login-box {
    min-width: 550px;
    width: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8rem;
}  
.login-box{
    padding-right: 10%;
}
.img{
    padding-left: 10%;
}
@media (max-width: 1024px) {
    .login-box {
        min-width: inherit;
        width: 100%;
   }  
    .login-container {
        background: #fff;
    }

    .login-container .img {
        display: none;
    }

    .login-container .login-box {
        width: inherit;
    }

    .login-page .login h1 {
        color: #000;
    }

    .login-box .container {
        max-width: 340px;
        padding-right: 0px;
        margin: 0px;
    }
}
</style>

<script type="text/javascript">
import LoginFormComponent from './Components/LoginForm.vue';
import { getAppContext } from 'utilities/helper';

export default {
    components: { LoginFormComponent },
    data() {
        return { regions: null, csrfToken: null };
    },

    created() {
        // Already signed in?
        const context = getAppContext();
        const profile = context.profile;
        if (profile) {
            this.$router.push('/user');
        } else {
            this.initAsync();
        }
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');


            // Read csrf token
            const token = await $.callPostApi(self, '/api/v1/csrftoken');
            if (token && typeof token === 'string') {
                self.regions = resp.data;
                self.csrfToken = token;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>