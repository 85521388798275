<template>
    <section>
        <!--  //暂时注释-->
        <!-- <section class="tutorial">
            <loading-indicator v-if="!categories" />
            <template v-else>
                <div v-for="(c, index) in categories" :key="index" class="container">
                    <div class="row">
                        <div class="col">
                            <h1>
                                {{ c.category.name }}
                            </h1>
                        </div>
                    </div>
                    <div v-if="c.items" class="row">
                        <posts-component :posts="c.items" />
                    </div>
                </div>
            </template>
       </section> -->
       <section class="core-products container">
            <div class="core-title">
                <h1>{{$t(environment?'home_td.home_td_serve':'home.home_experience') }}</h1>
                    <h3>{{$t(environment?'home_td.home_td_promise':'home.home_experience_describe')}}</h3>
                    <div class="d-flex">
                        <div class="commitment-icon">
                            <img src="@/assets/coins/png/huobi/disc@2x.png" alt="">
                        </div>
                        <div class="commitment-title disc">
                            <div >{{$t(environment?'home_td.home_td_allday':'home.home_major')}}</div>
                            <div>{{$t(environment?'home_td.home_td_support':'home.home_major_describe')}}</div>
                        </div>
                    </div>
                     <div class="d-flex">
                        <div class="commitment-icon">
                             <img src="@/assets/coins/png/huobi/contract@2x.png" alt="">
                        </div>
                        <div class="commitment-title">
                             <div >{{$t(environment?'home_td.home_td_channel':'home.home_timely')}}</div>
                            <div>{{$t(environment?'home_td.home_td_moresupport':'home.home_timely_describe')}}</div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="commitment-icon">
                            <img src="@/assets/coins/png/huobi/earnCoins@2x.png" alt="">
                        </div>
                        <div class="commitment-title">
                            <div >{{$t(environment?'home_td.home_td_resources':'home.home_extreme')}}</div>
                            <div>{{$t(environment?'home_td.home_td_educational':'home.home_extreme_describe')}}</div>
                        </div>
                    </div>
            </div>
            <div class="img"> <img src="@/assets/coins/png/huobi/coreProducts@2x.png" alt="" /></div>
       </section>
       <section class="core-products container diversification">
             <div class="img"> <img src="@/assets/coins/png/huobi/diversification@x2.png" alt="" /></div>
            <div class="core-title">
                <h1>{{$t(environment?'home_td.home_td_financial':'home.home_ecology')}}</h1>
                    <h3>{{$t(environment?'home_td.home_td_economic':'home.home_ecology_describe')}}</h3>
                    <div class="d-flex">
                        <div class="commitment-icon">
                            <img src="@/assets/coins/png/huobi/gift@2x.png" alt="">
                        </div>
                        <div class="commitment-title">
                            <div >{{$t(environment?'home_td.home_td_readymade':'home.home_spotgoods')}}</div>
                            <div>{{$t(environment?'home_td.home_td_mainstream':'home.home_spotgoods_describe')}}</div>
                        </div>
                    </div>
                     <div class="d-flex">
                        <div class="commitment-icon">
                            <img src="@/assets/coins/png/huobi/trades@2x.png" alt="">
                        </div>
                        <div class="commitment-title">
                             <div >{{$t(environment?'home_td.home_td_derivatives':'home.home_contract')}}</div>
                            <div>{{$t(environment?'home_td.home_td_trading':'home.home_contract_describe')}}</div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="commitment-icon">
                            <img src="@/assets/coins/png/huobi/chat@2x.png" alt="">
                        </div>
                         <div class="commitment-title">
                             <div >{{$t(environment?'home_td.home_td_Institutional':'home.home_options')}}</div>
                            <div>{{$t(environment?'home_td.home_td_frequency':'home.home_options_describe')}}</div>
                        </div>
                    </div>
            </div>
       </section>
       <section class="container trade">
            <div class="core-title">
                <h1>{{$t(environment?'home_td.home_td_exchange':'home.home_network')}}</h1>
                <h4>{{$t(environment?'home_td.home_td_festablished':'home.home_network_describe') }}</h4>
            </div>
            <div class="img"> <img src="@/assets/coins/png/huobi/bit_map.png" alt="" /></div>
       </section>
       <section class="core-products container safeStable">
            <div class="core-title">
                <h1>{{$t(environment?'home_td.home_td_guarantee':'home.home_Safety')}}</h1>
                <h3>{{$t(environment?'home_td.home_td_proactively':'home.home_Safety_describe')}}</h3>
                <h2>{{$t(environment?'home_td.home_td_regulations':'home.home_compliance')}}</h2>
            </div>
            <div class="img"> <img src="@/assets/coins/png/huobi/safeStable@2x.png" alt="" /></div>
       </section>
    </section>
</template>


<style scoped>
.tutorial h1{
    font-size: 26px;
    font-weight: 600;
}
.tutorial{
    margin-top: 60px;
}
.tutorial .container .post-item a{
    color: #000;
}
.core-products{
    width: 100%;
    display: flex;
    margin-top: 80px;
    min-height: 550px;
}
.core-title{
    padding: 10px;
    width: 45%;
}
.core-products .img{
    width: 55%;
}
.core-title ,.core-products .img{
    min-height: 400px;
}
.core-title h1{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #3D3D3D;
    text-align: left;
    margin-top: 80px;
}
.core-title h3{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    margin-top: 28px;
    color: rgba(102, 102, 102, 0.8)
}
.core-title .d-flex{
    margin-top: 48px;
}
.commitment-icon{
    margin-right: 20px;
}
.core-products .img img{
    width: 100%;
    min-width: 300px;
    height: 90%;
}
.commitment-icon img {
   width: 48px;
   height: 48px;
}
.commitment-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #3D3D3D;
}
.commitment-title >div{
    width: 100%;
}
.commitment-title >div:nth-child(1){
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #3D3D3D;
}
.commitment-title >div:nth-child(2){
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #3D3D3D;
}
.trade{
    margin-top: 20px;
}
.trade>div{
    width: 100%;
}
.trade h1,.trade h4{
    text-align: center;
}  
.trade h4{
   padding: 0 80px;
   margin-top: 20px;
}
.trade .core-title{
    min-height: 0px;
}  
.trade .img{
    min-height: 500px;
} 
.trade .img img{
   height: 500px;
   width: 100%;
   min-width: 600px;
} 
.core-title h2{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    margin-top: 24px;
}

@media (max-width: 1024px) {
    .tutorial{
        display: none;
    }
    .core-products{
        flex-direction: column;
    }
    .core-products>div{
        width: 100%;
    }
    .core-products .img{
        width: 100%;
    }
    .core-title h1,.core-title h3{
        text-align: center;
    }
    .core-title h1{
        margin-top: 20px;
    }
    .diversification {
        flex-direction: column-reverse;
    }
    .trade h4{
        padding: 0px;
    }
    .trade .img img{
        height: 160px;
        width: 96%;
        min-width: 90%;
    }
    .trade .img{
        min-height: 180px;
        text-align: center;
    }
    .safeStable h2{
        display: none;
    }
    .core-title{
        min-height: 300px;
    }
}

</style>

<script type="text/javascript">
import PostsComponent from '@/pages/College/Components/_Posts.vue';

export default {
  components: { PostsComponent },

    data() {
        return {
            categories: null
        };
    },
    computed: {
        environment:function(){
            return this.sysconfig.region==='de';
        }
    },
    watch: {
        /**
         * Update post content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            // this.initPageAsync();
        }
    },

    mounted() {
        //暂时注释
        // this.initPageAsync();
    },

    methods: {
        initPageAsync: async function () {
            const json = await $.callPostApi(this, '/api/v1/post/college');
            if (json && json.errcode === 0) {
                this.categories = json.data;
            }
        }
    }
}
</script>
