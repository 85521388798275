<template>
    <section>
        <!-- A verification image -->
        <!-- <vcode-image ref="vcodeComp" v-bind:useAccountKey="useAccountKey" /> -->

        <!-- Ask for SMS verification code -->
        <div class="form-group">
            <label>
                <!--
                    VCode type:
                    1: phone
                    2: email
                -->
                {{ $t(emailVCode === true ? 'vcode.label_email' : 'vcode.label_sms') }}
                <div v-if="!isAccountSpecifc" class="help-block">
                    {{ $t(emailVCode === true ? 'vcode.label_email_desp' : 'vcode.label_sms_desp') }}
                    <!-- : {{ regionCode }} {{ account }} -->
                </div>
            </label>
            <div class="row">
                <div class="col">
                    <div class="input-group">
                        <input name="Code" ref="smsInput" v-model="code" :placeholder="$t(emailVCode === true ? 'vcode.label_email' : 'vcode.label_sms')" pattern="\d*" type="text" autocomplete="off" class="form-control" data-val="true" data-val-required="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="4" data-val-regex="*" data-val-regex-pattern="^\d+$" />
                        <!-- show counting down? -->
                        <button v-if="countdown > 0" type="button" class="btn btn-secondary" disabled>{{ countdown }}S</button>
                        <button v-else type="button" class="btn btn-secondary" :class="{ 'btn-loading': sending }" :disabled="sending" @click="sendSms">
                            {{ $t('vcode.label_send') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['regionCode', 'account', 'useAccountKey', 'emailVCode', 'action'],

    data() {
        return {
            sending: false,
            countdown: 0,
            code: null
        };
    },

    computed: {
        isAccountSpecifc: function () {
            return 'true' === this.useAccountKey || true === this.useAccountKey;
        }
    },

    methods: {
        getSmsCode: function () {
            return $.trim(this.code);
        },

        sendSms: function () {
            const self = this;
            const apiUrl = self.getSmsUrl();
            const accountSpecific = this.isAccountSpecifc;

            ///////////////////////////////////////////////////////////////////////////////////
            // Validate the required image code
            // const model = self.$refs.vcodeComp.getVCode();
            // if (!model) {
            //     return false;
            // }
            // if ((!accountSpecific && true !== /^[\w]+$/gi.test(model.vcodeKey)) || true !== /^\d+$/gi.test(model.vcodeVal)) {
            //     $.top_error(self.$t('vcode.label_sms_invalid_code'));
            //     return false;
            // }

            ///////////////////////////////////////////////////////////////////////////////////
            // Validate the required account property.
            const model = {};
            if (!accountSpecific) {
                const account = this.account;
                var val = $.validate_phone(account, this.regionCode) || $.validate_email(account);
                if (!val || !val.length) {
                    self.$emit('missing-account');
                    return;
                }

                model.Account = val;
                model.RegionCode = this.regionCode;
            }

            self.sending = true;

            ///////////////////////////////////////////////////////////////////////////////////
            // Calling the server API to send sms.
            const callApi = () => {
                $.callPostApi(self, apiUrl, model)
                    .then((json) => {
                        if (json && json.errcode === 0) {
                            // refresh image code now
                            // self.$refs.vcodeComp.reloadImage(true);

                            // Create a timer.
                            const start = new Date();
                            const fn_update = function () {
                                var seconds = (new Date() - start) / 1000;
                                if (seconds >= 60) {
                                    self.countdown = 0;
                                } else {
                                    self.countdown = Math.round(60 - seconds);
                                    setTimeout(fn_update, 1000);
                                }
                            };
                            self.countdown = 60;
                            setTimeout(fn_update, 1000);
                        } else {
                            $.top_error(json.errmsg || self.$t('vcode.label_send_error'));
                        }
                    })
                    .catch((err) => {
                        // Unhandled errors
                        $.top_error(self.$t('vcode.label_send_error'));
                    })
                    .then(() => {
                        self.sending = false;
                    });
            };
            setTimeout(callApi, 100);
        },

        getSmsUrl: function () {
            // Validate the required action property.
            const action = '' + this.action;
            if (true === /^[a-z\-]+$/gi.test(action)) return '/api/v1/vcode/' + action;

            throw new Error(`Invalid action: ${this.action}`);
        }
    }
};
</script>
<style scoped>
.form-group .btn{
    margin-top: 0px;
}
.form-group .form-control{
    background: #fff;
}
.btn-secondary{
    background: #006CFF;
    color: #fff;
}
</style>