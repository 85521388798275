<template>
    <section class="page settings-page">
        <div v-if="!profile">
            <loading-indicator />
        </div>
        <template v-else>
            <section class="top-header">
                <div class="container">
                    <div class="row align-items-end">
                        <div class="col ">
                            <div class="fs-1">
                                <!-- vip status -->
                                <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                                    <img class="vipImage"  :src="vipImage" :alt="`VIP${profile.vipLevel}`">
                                    <!-- <div>{{ $t(profile.vipLevel > 10 ? 'user.label_premium_user' : 'user.vip' + profile.vipLevel) }}</div> -->
                                </div>
                                <div class="information"><div ><img src="@/assets/coins/png/huobi/avatar@2x.png" alt="" /></div><div>{{ profile.realName }}</div></div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div class="container setting-items">
                <div class="row">
                    <div class="verified">
                        <div class="certification">
                            <p>{{$t('user.label_id_verification')}}</p>
                            <p>{{$t('user.label_id_verification_desp')}}</p>
                            <div v-if="profile.verifiedLevel >= profile.requiredIDLevel"> {{ $t('home.home_account_verified') }}</div>
                            <router-link to="/user/verifyid" class="d-flex align-items-center" v-else>
                                    <div class="flex-fill"><span class="certification_title">{{$t('register.label_verification')}}</span></div>
                            </router-link>
                        </div>
                        <div class="img">
                            <img src="@/assets/coins/png/huobi/certification@2x.png" alt="" />
                        </div>
                    </div>
                    <div class="security">
                        <div class="title">{{ $t('home.header_account_security') }}</div>
                        <div class="bankcard">
                            <div><img src="@/assets/coins/png/huobi/setup@2x.png" alt="" /></div>
                            <div>
                                <div class="bankaccount"> {{ $t('user.label_bankaccount') }}</div>
                                <div>{{ $t('deposit.label_channel') }}</div>
                            </div>
                            <div class="binding">
                                <router-link :to="jumpRoute" class="d-flex align-items-center">
                                    <div  class="flex-fill">{{ $t('user.label_bankaccount') }}</div>
                                </router-link>
                            </div>
                        </div>
                        <div class="bankcard">
                            <div><img src="@/assets/coins/png/huobi/password@2x.png" alt="" /></div>
                            <div>
                                <div class="bankaccount"> {{ $t('user.label_password') }}</div>
                                <div>{{ $t('home_biss.commitment_2') }}</div>
                            </div>
                            <div class="binding">
                                <router-link to="/user/reset" class="d-flex align-items-center">
                                    <div  class="flex-fill">{{ $t('change_password.title') }}</div>
                                </router-link>
                            </div>
                        </div>
                        <div class="bankcard">
                            <div><img src="@/assets/coins/png/huobi/phoneNumber@2x.png" alt="" /></div>
                            <div>
                                <div class="bankaccount"> {{ $t('user.label_account') }}</div>
                                <div>{{ $t('home_biss.commitment_2') }}</div>
                            </div>
                            <div class="binding telephone" v-if="sysconfig.phoneSupported">
                                <router-link to="/user/phone" class="d-flex align-items-center">
                                    <div  class="flex-fill">{{ profile.phone }}</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="others">
                        <div v-if="sysconfig.emailSupported">
                            <router-link to="/user/email" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('register.label_email') }}</div>
                                <div class="fw-normal me-2">{{ profile.email }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div v-if="sysconfig.requiresWithdrawPassword === true">
                            <router-link to="/user/changewithdrawpwd" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_financial_password') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/user/balance" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('assets.assets_property') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/notices" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('notices.notice_title') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div  class="row">
                            <router-link to="/user/ga" class="d-flex align-items-center google">
                                <div class="flex-fill">{{ $t('ga.title') }}</div>
                                <div class="me-2">
                                    <span class="fw-normal" v-if="profile.isgaSet">{{ $t('ga.status_set') }}</span>
                                    <span class="unset" v-else>{{ $t('ga.status_unset') }}</span>
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/exchange/orders" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.header_exchange_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/futures/openorders" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.header_futures_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div  v-if="sysconfig.bOptionEnabled">
                            <router-link to="/boption/orders" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.header_boption_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/user/signout" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_signout') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="page-part">
                <div class="container">
                    <div class="row top-icons">
                        <div class="col">
                            <div>{{ profile.realName }}</div>
                            <div>
                                {{ $t('user.balance_amount') }} <b>{{ balance }}</b> {{ currencySymbol }}
                            </div>
                        </div>
                        <div class="col text-right">
                            <router-link to="/finance/deposit" class="btn btn-primary">{{ $t('user.deposit') }}</router-link>
                            <router-link to="/finance/withdraw" class="btn btn-default">{{ $t('user.withdraw') }}</router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </template>
    </section>
</template>

<style scoped>
.settings-page {
    background: #fff;
}

.top-header {
    color: var(--top-header-text);
    background: var(--top-header-bg);
    overflow: hidden;
}

.top-header::v-deep .spinner-border {
    border-width: 2px;
    opacity: 0.2;
}

.top-header .svg-icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
}

.top-header .vip-label {
    font-size: 1.25rem;
    color: var(--text-vip);
    border-radius: 0.25rem;
    font-weight: 400;
}

.icon-warning {
    height: 2rem;
    width: 2rem;
}

a svg {
    width: 1rem;
    height: 1rem;
}

ul {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

ul>li {
    list-style-type: none;
}

li>a {
    display: block;
    background: #fff;
    color: var(--text-primary);
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid var(--text-secondary);
    border-radius: .5rem;
    font-weight: 600;
}

li>a:hover {
    color: var(--primary-hover)
}
.certification p:nth-child(1){
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 0px;
}
.certification p:nth-child(2){
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #565656;
}
.certification a div{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #0273E5;
    margin-bottom: 0px;
}
.verified{
    border-radius: 2px;
    border: 1px solid #E6ECF2;
    height: 150px;
    margin-top: 8px;
    display: flex;
}
.verified .certification{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.certification .flex-fill{
    margin-top: 8px;
}
.verified .img{
    width: 30%;
}
.verified .img img{
    height: 100%;
    min-width: 250px;
}
.information{
    display:flex;
    align-items: center;
}
.information img{
    margin-right: 10px;
    width: 50px;
    height: 50px;
}
.security{
    margin-top: 10px;
    border-radius: 2px;
    border: 1px solid #E6ECF2;
    padding: 0px;
}
.security .title{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    height: 40px;
    background: #F2F6FA;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.security .bankcard{
    display: flex;
    justify-content:flex-start ;
    border-bottom: 1px solid #E6ECF2;
    width: calc(100% - 20px);
    margin-left: 10px;
    padding: 10px 0px;
}
.security .bankcard>div:nth-child(3){
    margin: auto;
    margin-right: 10px;
}
.security .bankcard>div:nth-child(1){
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.bankaccount{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.telephone .flex-fill{
    color: #565656;
}
.binding .flex-fill{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0273E5;
}
.others .flex-fill{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.others a>svg{
    fill: #000000;
}
.others {
    margin-top: 20px;
    border-radius: 2px;
    border: 1px solid #E6ECF2;
    margin-bottom: 20px;
}
.others >div{
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E6ECF2;
}
.others >div:nth-child(1){
    margin-top: 20px;
}
.certification_title{
    display: inline-block;
    border: 1px solid #ddd;
    min-width: 60px;
    text-align: center;
    border-radius: 3px;
}
.certification_title:hover{
   color: #fff;
   background: #0273E5;;
}
.me-2 .unset,.fw-normal {
    color: #0273E5;
}
.vipImage {
    position: relative;
    width: 100px;
    left: 6px;
    top: 3px;
}
@media (max-width: 1024px)
{
    .d-md-none  {
        display: flex !important;
    }
   .verified .img{
        display: none;
    }
    .verified .certification{
        width: 100%;
    }
    .vipImage {
        top: 0px;
    }
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';

import { setProfile } from 'utilities/helper';

// import vip image
import vip1 from '@/assets/images/vip/1.png'
import vip2 from '@/assets/images/vip/2.png'
import vip3 from '@/assets/images/vip/3.png'
import vip4 from '@/assets/images/vip/4.png'
import vip5 from '@/assets/images/vip/5.png'
import vip6 from '@/assets/images/vip/6.png'
import vip7 from '@/assets/images/vip/7.png'
import vipmax from '@/assets/images/vip/max.png'

export default {
    data() {
        return {
            vipImage:null,
            profile: null,
            id_status: NaN,
            jumpRoute:"/user/bankaccounts"
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },
    methods: {
        getVipImage(level) {
            if(!level) {
                this.vipImage = null;
            }
            const vipImage = {
                vip1,vip2,vip3,vip4,vip5,vip6,vip7,vipmax
            }
            if(level>7) {
                this.vipImage = vipImage.vipmax
            } else {
                this.vipImage = vipImage['vip' + level]
            }
        },
        async getBindBankStatus(){
            if(this.profile === null) return
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0 && json.data.length === 0) {
                this.jumpRoute = "/user/bankaccount"; 
            } else {
                this.jumpRoute=this.sysconfig.region === 'de'?"/user/bankaccounts":`/user/bankaccounts`;       
            }
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;
                self.getVipImage(profile.vipLevel)
                self.getBindBankStatus();
            }
        }
    }
};
</script>