<template>
    <div class="trade bg-content">
        <div class="mod trade-panel">
            <div class="mod-head tabs bg-title">
                <span data-trade-type="exchange" class="mock-a cur">{{ $t('exchange.type_limited_price') }}</span>
            </div>
            <div class="mod-body">
                <div class="table-panel-tabs">
                    <button class="btn color-up" :class="{ active: visible_type === 'buy' }" @click="visible_type = 'buy'">{{ $t('exchange.orders_buy') }}</button>
                    <button class="btn color-down" :class="{ active: visible_type === 'sell' }" @click="visible_type = 'sell'">{{ $t('exchange.orders_sell') }}</button>
                </div>
                <create-order-form ref="buyForm" :symbol="symbol" :available_balance="quote_currency" type="buy" :visible_type="visible_type" @order-created="onOrderCreated" />
                <create-order-form ref="sellForm" :symbol="symbol" :available_balance="base_currency" type="sell" :visible_type="visible_type" @order-created="onOrderCreated" />
            </div>
        </div>
    </div>
</template>

<script>
import CreateOrderForm from './Exchange_CreateOrderForm.vue';
export default {
    components: { CreateOrderForm },

    props: ['symbol'],

    data() {
        return {
            quote_currency: '--',
            base_currency: '--',
            visible_type: 'buy'
        };
    },

    created() {
        this.syncBalance();
    },

    methods: {
        setOrderPrice: function (p) {
            this.$refs.buyForm.setOrderPrice(p);
            this.$refs.sellForm.setOrderPrice(p);
        },

        setVisibleType: function (type) {
            this.visible_type = type;
        },

        syncBalance: function () {
            const self = this;
            const meta = this.symbol.metadata;
            const url = '/api/v1/balance/get2?currency=' + encodeURIComponent(meta.base_symbol + ',' + meta.quote_symbol);
            let num_attempts = 0;
            function _do_sync() {
                $.callPostApi(self, url)
                    .then((data) => {
                        let quoteAmount = 0,
                            baseAmount = 0;

                        if (data && data.length) {
                            $(data).each((index, item) => {
                                let amount = item.balance + item.frozen;

                                if (item.currency === meta.base_symbol) {
                                    baseAmount = amount.toFixedBalance(meta.volumePrecision);
                                } else if (item.currency === meta.quote_symbol) {
                                    quoteAmount = amount.toFixedBalance(meta.volumePrecision + meta.pricePrecision);
                                }
                            });
                        }

                        self.base_currency = baseAmount;
                        self.quote_currency = quoteAmount;
                    })
                    .catch((err) => {
                        console.error(`ERROR: ${err}`);
                        if (++num_attempts < 3) {
                            setTimeout(_do_sync, 1000);
                        }
                    });
            }
            setTimeout(_do_sync, 500);
        },

        onOrderCreated: function () {
            this.$emit('order-created');

            // Update balance after an order is created.
            this.syncBalance();
        }
    }
};
</script>