<template>
    <div ref="closeOrderModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">{{ $t('futures.label_close_order_title') }}</h4>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <form method="post" action="javascript:;" @submit="doCloseOrder">
                    <div v-if="order" class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div>
                                    <b>{{ order.numHands }} {{ $t('futures.label_unit') }} {{ order.symbolName }}</b>
                                    <span v-if="order.buyUp" class="color-up"> {{ $t('futures.label_buy_up') }} <i class="fa fa-arrow-up"></i> </span>
                                    <span v-else class="color-down"> {{ $t('futures.label_buy_down') }} <i class="fa fa-arrow-down"></i> </span>
                                </div>
                                <div>
                                    <small>{{ new Date(order.timeCreated).formatDateTime() }}</small>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_margin_pct') }}</label> {{ 100 / order.leverage }}%
                            </div>
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_fee') }}</label> {{ order.totalFee }} USDT
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_create_price') }}</label>
                                <span v-if="order.marketPrice === true">{{ $t('futures.label_market_price') }}</span>
                                <span v-else>{{ order.createPrice.toFixed(order.pricePrecision) }}</span>
                            </div>
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_avg_price') }}</label> {{ order.matchPrice.toFixed(order.pricePrecision) }}
                            </div>
                        </div>
                        <div class="row" v-if="!isNaN(order.currentPrice)">
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_current_price') }}</label> {{ order.currentPrice.toFixed(order.pricePrecision) }}
                            </div>
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_profit') }}</label> {{ order.currentProfit.toFixed(2) }} USDT
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_current_price') }}</label> --
                            </div>
                            <div class="col">
                                <label class="text-muted">{{ $t('futures.order_profit') }}</label> --
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="submitting" :class="{ 'btn-loading': submitting }">{{ $t('futures.label_close_order') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            order: null,
            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.closeOrderModal, { dismissible: false });
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        updateOrder: function () {
            this.$forceUpdate();
        },

        openModal: function (order) {
            if (!order) {
                throw new Error('Invalid parameter');
            }

            this.order = order;
            // const self = this;

            // Vue.nextTick(() => {
            //     $(self.$refs.closeOrderModal).modal('show');
            // });
            this.popupModal.show();
        },

        doCloseOrder: function () {
            const self = this;
            if (this.order && this.order.orderId) {
                const orderId = this.order.orderId;
                self.submitting = true;

                // Do not call $.callPostApi in a modal
                $.callPostApi(self, '/api/v1/futures/closeorder?id=' + encodeURIComponent(orderId))
                    .then(function (json) {
                        setTimeout(() => {
                            self.submitting = false;

                            if (json && json.errcode === 0) {
                                // $(self.$refs.closeOrderModal).modal('hide');
                                self.popupModal.hide();

                                $.top_alert(self.$t('general.submitted'));

                                // Raise an event when an order has been closed.
                                self.$emit('order-closed', orderId);

                                // remove the order from local cache.
                                // for (let i = 0; i < self.openOrders.length; i++) {
                                //     if (self.openOrders[i].orderId === orderId) {
                                //         self.openOrders.splice(i, 1);
                                //         break;
                                //     }
                                // }
                            } else {
                                $.top_error(json.errmsg);
                            }
                        }, 200);
                    })
                    .catch(function (err) {
                        self.submitting = false;

                        $.top_error(self.$t('general.operation_error'));
                        return;
                    });
            }
        }
    }
};
</script>