<template>
    <footer class="footer-simple">
        <ul>
            <li>
                <router-link to="/">{{ $t('home.title') }}</router-link>
            </li>
            <template v-if="footer && footer.length > 0">
                <li v-for="item in footer[0].items" :key="item.id">
                    <router-link rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                </li>
            </template>
            <li>&copy; {{ new Date().getFullYear() }} {{ sysconfig.name }}</li>
        </ul>
    </footer>
</template>


<script>
import { getAppContext } from 'utilities/helper';
export default {
    data() {
        return { context: null };
    },

    computed: {
        footer: function () {
            return this.context.homeConfig ? this.context.homeConfig.footer : null;
        }
    },

    created() {
        this.context = getAppContext();
    }
};
</script>
<style scoped>
@media (max-width: 768px) {
    .footer-simple {
        padding: 0;
    }
}
</style>