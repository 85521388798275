<template>
    <div class="login container">
        <div class="login-inner">
            <form action="javascript:void(0);" method="post" class="login-form" @submit="doSubmit">
                <h1>{{ $t('reset_password.title') }}</h1>

                <!--
                    Does both phone number and email are supported?
                -->
                <div class="page-top-nav mb-3" v-if="sysconfig.phoneSupported && sysconfig.emailSupported">
                    <li :class="{ active: !emailVCode }">
                        <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
                    </li>
                    <li :class="{ active: emailVCode }">
                        <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
                    </li>
                </div>

                <div class="form-group">
                    <label v-if="!(sysconfig.phoneSupported && sysconfig.emailSupported)">{{ $t('reset_password.label_account') }}</label>

                    <template v-if="emailVCode">
                        <!-- Email account -->
                        <!-- Required by the interface -->
                        <input type="hidden" name="RegionCode" value="+1" />
                        <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
                    </template>
                    <div v-else class="d-flex">
                        <region-selector v-bind:regions="regions" :regionCode="regionCode" @change="onRegionCodeChanged" />
                        <div class="flex-fill">
                            <input name="Name" ref="accountInput" v-model="account" pattern="\d*" type="text" class="form-control phone round-right" data-val="true" data-val-required="*" :placeholder="$t('reset_password.label_account')" />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>{{ $t('reset_password.label_password') }}</label>
                    <div class="password-box">
                        <input name="Password" v-model="password" :type="showPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" :placeholder="$t('reset_password.label_password')" />
                        <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ $t('reset_password.label_confirm_password') }}</label>
                    <div class="password-box">
                        <input name="ConfirmPassword" v-model="confirmPassword" :type="showConfirmPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-equalto-other="Password" data-val-equalto="*" :placeholder="$t('reset_password.label_confirm_password')" />
                        <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>

                <sms-verification :emailVCode="emailVCode" :regionCode="regionCode" :account="account" action="reset-password" />
                <div class="form-group">
                    <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                        {{ $t('reset_password.title') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
    components: { RegionSelector },
    props: ['regions'],
    data() {
        return {
            // which types of account is in use?
            emailVCode: false,

            account: null,
            regionCode: null,
            password: null,
            confirmPassword: null,

            showInputs: true,

            submitting: false,
            completed: false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    watch: {
        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        // goNextPage: function (e) {
        //     const frm = $(e.target);
        //     if (frm.valid()) {
        //         this.showInputs = false;
        //     }
        //     return false;
        // },

        // goPrevPage: function () {
        //     this.showInputs = true;
        // },

        doSubmit: function (e) {
            const frm = $(e.target);
            const self = this;

            if (frm.valid()) {
                this.submitting = true;

                const data = frm.serialize();
                $.callPostApi(self, '/api/v1/account/resetpassword', data)
                    .then((json) => {
                        if (json && json.errcode === 0) {
                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                self.$router.push('/user');
                            }, 500);
                        } else {
                            $.top_error(json.errmsg);
                        }
                    })
                    .catch(function (err) {
                        $.top_error(self.$t('general.operation_error'));
                    })
                    .then(() => {
                        self.submitting = false;
                    });
            }
        },

        focusAccount: function () {
            this.$refs.accountInput.focus();
        },

        onRegionCodeChanged: function (val) {
            this.regionCode = val;
        }
    }
};
</script>