<template>
    <div v-if="posts" class="row">
        <!-- posts within the category. -->
        <div v-for="post in posts" :key="post.id" class="col-12 col-sm-6 col-md-3 post-item mb-4">
            <router-link :to="`/post/${post.id}`">
                <div :style="`background-image: url(${post.imageUrl})`" class="img-wrapper">
                    <img :src="post.imageUrl" :alt="post.title" />
                </div>
                <div class="pt-2">{{ post.title }}</div>

            </router-link>
        </div>
    </div>
</template>

<style>
.post-item a{
    color: #000;
}
.tutorial .container .post-item a{
    color: #000;
}
.post-item a {
    display: block;
    transition: all .4s;
}
.row .post-item a :hover{
    background: none;
    color: #000;
}
.post-item img {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0;
}

.post-item div.img-wrapper {
    background: transparent no-repeat center center;
    background-size: 100% 100%;
    transition: all .4s;
}

.post-item a:hover div.img-wrapper {
    background-size: 120% 120%;
}
.posts-page a:hover{
    color: #000;
}
</style>

<script lang="js">
export default {
    props: ['posts']
};
</script>