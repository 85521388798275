<template>
    <section class="page balance-page">

        <section v-if="bcConfig" class="w-100">
            <div class="container px-3">
                <div class="balance-actions">
                    <div class="title"><span>{{ $t('assets.total_assets') }}</span></div>
                    <div class="allAction">
                        <div class="action coins">
                            <router-link to="/finance/orders" class="btn btn-primary">
                                {{ $t('balance.orders_title') }}
                            </router-link>
                        </div>
                        <div class="action">
                            <router-link to="/finance/history" class="btn btn-primary">
                                {{ $t('balance.history_title') }}
                            </router-link>
                        </div>
                        <div class="action">
                            <a href="javascript:;" @click="showExchangeModal" class="btn btn-primary">
                                {{ $t('assets.label_exchange') }}
                            </a>
                        </div>
                    </div>

                </div>
                <!-- balance list here -->
                <balance-list-multi-coins :balance_list="balance_list" :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken" @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<style scoped>
.page {
    background: #fff;
    color: #000;
}
.page .container{
    background: #fff;
    margin-top: 20px;
    padding-top: 15px;
}
.top-header{
    background: #F2F2F2;
}
.balance-actions{
    display: flex;   
    align-items: center;
    border-bottom:none;
    padding-bottom: 0px;
}
.allAction{
    display: flex;
    margin-right: 5px;
}
.balance-actions .title{
    margin: auto;
    margin-left: 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
}
.action a{
    margin-left: 4px;
    border-radius: 7px;
    border: 1px solid #0273E5;
    background: #fff;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #0273E5;
}
.action a:hover{
    background: #0273E5;
    color: #fff;
}
 .btn-primary:not(:disabled):not(.disabled):active{
    background: none;
 }
.coins a{
    color: #0273E5;
    background: #fff;
}
.container .title-row{
    color: #000;
}
.container{
    border-radius: 7px;
}
@media (max-width: 1024px) {
    .balance-actions{
        display: block;   
        padding-bottom: 0px;
    }
    .balance-actions .title span{ 
        border-bottom: 2px solid #0274E0;
        font-family: PingFangSC, PingFang SC;
        color: #0274E0;
    }
    .balance-actions .title{
        margin-bottom: 10px;
    }
    .balance-actions .action a{
       margin-left: 13px;  
    }
    .balance-actions .action:nth-child(1) a {
        margin-left: 0px;
    }
}
</style>

<script>
import AssetExchangeModal from './Components/AssetExchangeModal-4.4.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';
import Deposit from '../Finance/Deposit.vue';

let g_assetTypes = [];

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceListMultiCoins, Deposit },

    data() {
        return {
            balance_list: null,
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false
        };
    },

    created() {
        this.initAsync();
    },

    methods: {
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            let ftItem = null;
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }

                if (currency === 'FTUSDT') {
                    ftItem = item;
                } else {
                    output.push(item);
                }
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            // Add futures currency as the last one
            if (ftItem) {
                output.push(ftItem);
            }

            self.balance_list = output;
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },

    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    const val = balance.balance + balance.frozen;
    this.available = Math.abs(val) < 0.000001 ? 0 : (val).formatBalance(precision);
    this.balance = balance.balance < 0.000001 ? 0 : balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>