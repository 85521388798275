<template>
    <div class="list">

        <table class="table">
            <thead class="head">
                <tr>
                    <th>{{ $t('coin_treasure.currency') }}</th>
                    <th>{{ $t('coin_treasure.subscription_date') }}</th>
                    <th>{{ $t('coin_treasure.lump_sum') }}</th>
                    <th>{{ $t('coin_treasure.cumulative_income') }}</th>
                    <!-- <th>{{ $t('coin_treasure.level_reward') }}</th> -->
                    <!-- <th>{{$t('coin_treasure.maturity_date')}}</th> -->
                    <th>{{ $t('coin_treasure.state') }}</th>
                    <!-- <th>{{$t('futures.order_action')}}</th> -->
                </tr>
            </thead>
            <tbody class="body">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.token }}</td>
                    <td>{{ new Date(item.timeCreated).formatDate() }}</td>
                    <td>{{ item.amount }}</td>

                    <td> {{ item.interests }}</td>
                    <!-- <td> {{ item.extraInterests }}</td> -->
                    <!-- <td> {{ new Date(item.timeUpdated).formatDate() }}</td> -->
                    <td> {{ statusToText(item.status) }}</td>
                    <!-- <td v-if="item.status === 1"><button class="btn btn-primary" @click="redeemFunc(item)">{{$t('coin_treasure.early_redemption')}}</button></td> -->
                    <!-- <td v-if="item.status === 2">{{$t('coin_treasure.redemption')}}</td> -->
                    <!-- <td v-if="item.status === 3">{{$t('coin_treasure.redeemed')}}</td> -->
                </tr>
            </tbody>
        </table>
        <div v-if="isShow">
            <Dialog ref="earnModal" :data="data"></Dialog>
        </div>
    </div>
</template>

<script>

// import Coin from '../../Components/Coin.vue';
import Dialog from './RedeemDialog.vue';

export default {
    components: { Dialog },
    props: ['tableData', 'type'],
    data() {
        return {
            uid: null,
            amount: 0,
            data: {},
            isShow: false
        };
    },
    created() {
    },
    mounted() {

    },
    methods: {
        redeemFunc(data) {
            // this.uid = data.uid
            // this.amount= data.amount
            this.isShow = true
            this.data = data
            // 重新挂载组件
            this.$nextTick(() => {
                this.showDialog()
            })
        },
        hideModel() {
            // this.isShow = false
        },
        showDialog: function () {
            const modal = this.$refs.earnModal
            modal.showModal();
        },
        statusToText(status) {
            switch (status) {
                case 1:
                    return this.$t('coin_treasure.interest_accruing');;
                case 2:
                    return this.$t('coin_treasure.redemption');
                case 3:
                    return this.$t('coin_treasure.redeemed');
            }
            return '';
        }
    },
};
</script>
<style scoped>
.table > :not(caption) > * > * {
    background: #fff;
    color: #000;
}
.earn-table .table .head th {
    color: #999;
}
</style>
