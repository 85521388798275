<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <div>
                    <div class="market-title">
                        <div>{{ $t('orders.label_symbol') }}</div>
                        <div>{{ $t('home.price') }}</div>
                        <div>{{ $t('home.24h_change') }}</div>
                    </div>
                    <div class="table_content">
                        <div class="market-table" v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                            <div class="text-uppercase d-flex align-items-center">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div growing-ignore="true">${{ symbol.price }}</div>
                            <div growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm','coinCype'],
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }
            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                return data
            } else {
                // Return symbols for the specified type.
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });
                return data
            }
        }
    },
    methods: {
        gotoTrade: function (sym) {
            // this.$emit('symbol-selected', sym);
            const self = this;
            console.log(sym);
            Vue.nextTick(() => {
                // then route to the trade page for the specified symbol.
                const type = sym.metadata.type;
                const route = (type === 1 ? '/boption/' : (type === 3 ? '/exchange/' : '/futures/')) + sym.metadata.symbol.toLowerCase();
                self.$router.push({ path: route });
            });
        },
    }
};
</script>
<style scoped>
.homepage-market .market-wrap dl dt{
    display: flex;
}
.market-wrap dl dt>div{
    width: 30%;
}
.market-wrap dl dt > div:last-child {
    width:30%;
}
.market-wrap .market-title{
    display:flex;
}
.market-wrap .market-title>div{
    width:30%;
}
.market-wrap .market-title>div:nth-child(1){
    width:40%;
    padding-left: 20px;
}
.market-table{
    display: flex;
}
.market-wrap .market-table>div{
    width:30%;
    display: flex;
    align-items: center;
}
.market-wrap .market-table>div:nth-child(1){
    width:40%;
}
.market-wrap .market-table>div:nth-child(3){
    text-align: center;
}
.market-wrap .market-title>div:nth-child(3){
    text-align: left;
}
.market-wrap .market-title{
    margin-bottom: 10px;
}
.market-table{
    margin-bottom: 10px;
}
.table_content{
    height: 360px;
    overflow: auto;
}
.market-title >div{
    color: #4d5462;
}
.market-table div{
    color: #d7ddeb;
    font-size:12px;
    font-weight: 600;
}
</style>