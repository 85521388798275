<template>
    <nav class="navbar">
        <div ref="symbolsSideNav" class="offcanvas offcanvas-start" tabindex="-1" id="symbolsNavbar">
            <div class="offcanvas-header">
                <ul class="nav nav-underline">
                    <li v-if="sysconfig.bOptionEnabled" class="nav-item">
                        <a :class="{ active: type === 1 }" @click="showSymbols(1)" class="nav-link" href="javascript:void(0)">{{ $t('general.boption') }}</a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: type === 2 }" @click="showSymbols(2)" class="nav-link" href="javascript:void(0)">{{ $t('general.futures') }}</a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: type === 3 }" @click="showSymbols(3)" class="nav-link" href="javascript:void(0)">{{ $t('general.exchange') }}</a>
                    </li>
                </ul>
            </div>
            <div class="input-group">
                <div class="input-group-text">
                    <svg viewBox="0 0 1024 1024" width="32" height="32">
                        <path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" fill="#666666" p-id="2868"></path>
                    </svg>
                </div>
                <input type="text" class="form-control" v-model="search_term" placeholder="" />
            </div>
            <div class="offcanvas-body">
                <loading-indicator v-if="!filteredSymbols" />
                <template v-else>
                    <ul>
                        <li  v-for="(sym, index) in filteredSymbols" :key="index">
                            <div class="d-flex align-items-center" v-if="sym.metadata.id === symbol.metadata.id">
                                <!-- symbol icon -->
                                <token-icon :symbol="sym" />

                                <!-- symbol name -->
                                <b class="ms-2">{{ sym.metadata.name }}</b>
                            </div>
                            <a v-else @click="goto(sym)" href="javascript:void(0)" class="d-flex align-items-center">
                                <!-- symbol icon -->
                                <token-icon :symbol="sym" />

                                <!-- symbol name -->
                                <b class="ms-2 flex-fill">{{ sym.metadata.name }}</b>

                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </nav>
</template>
<style scoped>
.offcanvas {
    background-color: var(--bg-primary);
}
</style>
<script>
import { Offcanvas } from 'bootstrap';
import { getSymbolManagerAsync } from 'utilities/helper';

import '@/assets/svg/icon-arrow-right.svg';
import TokenIcon from './TokenIcon.vue';
import { getAppContext } from 'utilities/helper';
export default {
    components: { TokenIcon },
    props: ['symbol'],

    data() {
        return {
            bsOffcanvas: null,
            type: -1,
            symbols: null,
            search_term:null
        }
    },
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }
            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.search_term && typeof this.search_term === 'string'
                    ? this.search_term.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.type && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                return data
            } else {
                // Return symbols for the specified type.
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.type && (testMode || !s.metadata.testOnly);
                });
                return data
            }
        }
    },

    mounted() {
        this.type = this.symbol.metadata.type;
        this.bsOffcanvas = Offcanvas.getOrCreateInstance(this.$refs.symbolsSideNav);
    },

    methods: {
        show: function () {
            // display the sidenav
            this.symbols = null;
            this.bsOffcanvas.show();

            // refresh symbol list
            this.showSymbols(this.type * 1);
        },

        showSymbols: function (type) {
            this.type = type;

            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                const output = [];
                mgr.getSymbols(type).forEach(sym => {
                    output.push({
                        metadata: {
                            id: sym.id,
                            name: sym.name,
                            type: sym.type,
                            symbol: sym.symbol,
                            baseSymbol: sym.baseSymbol,
                            svgIcon: sym.svgIcon
                        }
                    });
                });
                self.symbols = output;
            });
        },

        goto: function (sym) {
            // close the offset-canvas first
            const nav = Offcanvas.getInstance(this.$refs.symbolsSideNav);
            if (nav) {
                nav.hide();
            }

            const self = this;
            Vue.nextTick(() => {
                // then route to the trade page for the specified symbol.
                const type = sym.metadata.type;
                const route = (type === 1 ? '/boption/' : (type === 3 ? '/exchange/' : '/futures/')) + sym.metadata.symbol.toLowerCase();
                self.$router.push({ path: route });
            });
        }
    }
}
</script>

<style scoped>
.input-group{
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    }
.input-group  .input-group-text,.input-group  .form-control{
    height: 30px;
    background: #1a1c24;
    border: 1px solid #1a1c24;
}
.input-group  .input-group-text{
    width: 40px;
    height: 30px;
    background: #1a1c24;
    border: 1px solid #1a1c24;
    }
    .input-group .form-control:focus{
    border: none;
}

.nav-link {
    min-width: 5rem;
    text-align: center;
    color: #fff;
    font-weight: 600
}

li {
    list-style-type: none;
}

.offcanvas-body li>a,
.offcanvas-body li>div {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid var(--divider);
    color: #fff;
}

a>svg {
    height: 1rem;
    width: 1rem;
}
.nav-underline .nav-link.active{
    color: #fff;
}
#symbolsNavbar .input-group .form-control{
    color: #fff;
}
</style>