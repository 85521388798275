<template>
    <div class="content-top">
        <!-- Import the symbols offset canvas -->
        <symbols-side-nav ref="symbolsSideNav" :symbol="symbol" />
        <div class="side-border"  :class="{'left-side':sysconfig.region==='de'}">
                <div >
                        <div class="col page-top-nav">
                            <ul>
                                <li :class="{ active: selected_type === 2 }">
                                    <a href="javascript:;" @click="selected(2)">{{ $t('general.futures') }}</a>
                                </li>
                                <li :class="{ active: selected_type === 3 }">
                                    <a href="javascript:;" @click="selected(3)">{{ $t('general.exchange') }}</a>
                                </li>
                                <li v-if="sysconfig.bOptionEnabled === true" :class="{ active: selected_type === 1 }">
                                    <a href="javascript:;" @click="selected(1)">{{ $t('general.boption') }}</a>
                                </li>
                            </ul>
                        </div>
                </div>
                <div class="input-group">
                    <div class="input-group-text">
                        <svg viewBox="0 0 1024 1024" width="32" height="32">
                            <path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" fill="#666666" p-id="2868"></path>
                        </svg>
                    </div>
                    <input type="text" class="form-control" v-model="search_term" placeholder="" />
                </div>
                    <!-- symbols -->
                <div class="row">
                    <div class="col">
                        <CoinsSymbolList  :title="$t(selectTitle)" :symbols="allSymbols" :typeFilter="selected_type" :searchTerm="search_term" />
                    </div>
                </div>
                <div class="account">
                    <div class="account-title"><span>{{$t('assets.account_type')}}</span></div>
                    <div class="account-content">
                        <div @click="jump_route()">{{$t('general.deposit')}}</div>
                        <div @click="jump_route()">{{$t('general.withdraw')}}</div>
                        <div @click="jump_route()">{{$t('assets.label_exchange') }}</div>
                    </div>
                </div>
        </div>
        <div class="left" :class="{'trading-left':sysconfig.region==='de'}">
            <div class="trading-chart">
                <div class="ticker bg-title text-content">
                    <!--
                    FOR VERSION 4.4
                    -->
                    <template v-if="$version === '4.4'">
                        <div class="d-none d-md-block">
                            <!-- FOR LARGE SCREEN -->
                            <div class="d-flex flex-row align-items-center">
                                <div stop="1" class="symbol-name">
                                    <div class="small">{{ $t('general.exchange') }}</div>
                                    <div class="dropdown-toggle" @click="showSideNav">{{ symbol.metadata.name }}</div>
                                    <div  class="metadata-name" v-if="sysconfig.region==='de'">{{ symbol.metadata.name }}</div>
                                </div>

                                <div growing-ignore="true" class="price-container" :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                                    <div>{{ symbol.price }}</div>
                                    <div class="price-change">{{ symbol.price_change }}</div>
                                </div>
                                <dl growing-ignore="true" class="change">
                                    <dt class="text-label">{{ $t('exchange.24h_change') }}</dt>
                                    <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                                </dl>
                                <dl growing-ignore="true" class="low ms-3">
                                    <dt class="text-label">{{ $t('exchange.24h_low') }}</dt>
                                    <dd>{{ symbol.day_low }}</dd>
                                </dl>
                                <dl growing-ignore="true" class="high ms-3">
                                    <dt class="text-label">{{ $t('exchange.24h_high') }}</dt>
                                    <dd>{{ symbol.day_high }}</dd>
                                </dl>
                                <dl growing-ignore="true" class="amount ms-3">
                                    <dt class="text-label">{{ $t('exchange.24h_vol') }}</dt>
                                    <dd>{{ symbol.day_vol }}</dd>
                                </dl>
                            </div>
                            <div class="ipad-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                        </div>
                        <div class="d-block d-md-none sm-trade-header">
                            <!-- FOR SMALL SCREENS -->
                            <div class="d-flex flex-row align-items-center">
                                <div class="d-flex flex-column">
                                    <div stop="1" class="symbol-name">
                                        <div class="small">{{ $t('general.exchange') }}</div>
                                        <div class="dropdown-toggle" @click="showSideNav">{{ symbol.metadata.name }}</div>
                                    </div>

                                    <!-- Current price -->
                                    <div growing-ignore="true" class="price-container" :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                                        <div>{{ symbol.price }}</div>
                                        <div class="price-change">{{ symbol.price_change }}</div>
                                    </div>
                                </div>

                                <div>
                                    <dl growing-ignore="true" class="change d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('exchange.24h_change') }}</dt>
                                        <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="low d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('exchange.24h_low') }}</dt>
                                        <dd>{{ symbol.day_low }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="high d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('exchange.24h_high') }}</dt>
                                        <dd>{{ symbol.day_high }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="amount d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('exchange.24h_vol') }}</dt>
                                        <dd>{{ symbol.day_vol }}</dd>
                                    </dl>
                                </div>
                                <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div stop="1" class="symbol-name">
                            <div class="d-flex flex-row">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ $t('general.exchange') }}
                                    </button>
                                    <ul class="dropdown-menu">
                                        <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                        <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange') }}</router-link>
                                        <router-link v-if="sysconfig.bOptionEnabled" to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                    </ul>
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ symbol.metadata.name }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <router-link v-for="sym in symbols" :key="sym.id" :to="'/exchange/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div growing-ignore="true" v-bind:class="'price-container ' + (symbol.up ? 'color-up' : 'color-down')">
                            <div>{{ symbol.price }}</div>
                            <div class="price-change">{{ symbol.price_change }}</div>
                        </div>
                        <dl growing-ignore="true" class="change">
                            <dt class="text-label">{{ $t('exchange.24h_change') }}</dt>
                            <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="low d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_low') }}</dt>
                            <dd>{{ symbol.day_low }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="high d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_high') }}</dt>
                            <dd>{{ symbol.day_high }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="amount d-none d-sm-block">
                            <dt class="text-label">{{ $t('exchange.24h_vol') }}</dt>
                            <dd>{{ symbol.day_vol }}</dd>
                        </dl>
                        <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                    </template>
                </div>
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
            <create-order-panel ref="createOrderPanel" :symbol="symbol" @order-created="onOrderCreated" />
            <div class="bottom-trade">
                <div class="row">
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="showTrade('buy')">{{ $t('exchange.orders_buy') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="showTrade('sell')">{{ $t('exchange.orders_sell') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="order-books">
                <div class="mod-body bg-content">
                    <dl>
                        <dt class="bg-title text-label">
                            <span class="price">{{ $t('exchange.depth_price') }} ({{ symbol.metadata.quote_symbol }})</span>
                            <span class="amount">{{ $t('exchange.depth_volume') }} ({{ symbol.metadata.base_symbol }})</span>
                            <span class="total">{{ $t('exchange.depth_total_volume') }} ({{ symbol.metadata.base_symbol }})</span>
                        </dt>
                        <dd class="now-pric text-content">
                            <div class="asks">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.asks" v-bind:key="order.key">
                                    <span class="price color-down">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                </p>
                            </div>
                            <div class="now-price">
                                <dl>
                                    <dt v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price }}</dt>
                                </dl>
                            </div>
                            <div class="bids">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.bids" v-bind:key="order.key">
                                    <span class="price color-up">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                </p>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SymbolInfo } from 'utilities/SymbolInfo.js';
import CoinsSymbolList from './CoinsSymbolList.vue';
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import CreateOrderPanel from './Exchange_CreateOrderPanel.vue';
import SymbolsSideNav from '../../Components/_SymbolsSideNav.vue';

// The date time when the last quote is received.
let g_destoryed = false;
let g_symbolMap = {};
let g_lastQuoteTime = new Date();
let g_quoteTimerId = 0;

export default {
    components: { CreateOrderPanel, SymbolsSideNav,CoinsSymbolList},

    props: ['symbol'],

    data() {
        return {
            order_books: {
                asks: [],
                bids: []
            },
            symbols: [],
            chartVersion: 0,
            // Indicates whether the component has been destoyed or not.
            destroyed: false,
            selected_type: 3,
            search_term: null,
            allSymbols: null,
            selectTitle:'general.futures'
        };
    },

    created() {
        g_destoryed = false;
        g_symbolMap = {};
        this.destroyed = false;
        this.getExchangeSymbols();
    },

    beforeDestroy() {
        console.log(`## Destroy the exchange page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown .dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
    },
    beforeDestroy() {
        g_destoryed = true;
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        jump_route:function(){
            this.$router.push({ path:'/user/balance' });
        },
        selected:function(type){
            this.selected_type =type;
            if(type ===2){
               this.selectTitle= 'general.futures';
            }else if(type ===3){
               this.selectTitle= 'general.exchange';
            }else{
               this.selectTitle= 'general.boption';
            }
            
        },
        showSideNav: function () {
            this.$refs.symbolsSideNav.show()
        },

        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.sysconfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            // Start a socket io connection
            const pricePrecison = sym.metadata.pricePrecision;
            const amountPrecison = sym.metadata.volumePrecision;
            function parse_depth(src, key_prefix) {
                let total_vol = 0;
                const arr = [];
                for (let i = 0; i < src.length; i++) {
                    let item = src[i];
                    total_vol += item[1];
                    arr[i] = {
                        key: key_prefix + i,
                        price: item[0].toFixed(pricePrecison),
                        volume: item[1].toFixed(amountPrecison),
                        total: total_vol.toFixed(amountPrecison)
                    };
                }

                return arr;
            }

            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            $.initSocketIo(
                '/f' + this.symbol.metadata.id,
                (quote) => {
                    if (self.destroyed === false) {
                        if (self.chartVersion !== chartVersion) {
                            return;
                        }

                        // Is there a big gap for the latest quote?
                        const now = new Date();
                        const gap = now - g_lastQuoteTime;
                        if (gap > 300 * 1000) {
                            setTimeout(() => {
                                console.log('#### rebuild chart ######');
                                $.closeSocketIo();
                                self.initChartAsync();
                            }, 0);
                        } else {
                            g_lastQuoteTime = now;

                            // Update quote.
                            sym.updateRtqs(quote);
                            chart.updateRtqsToChart(quote);
                        }
                    }
                },
                (depth) => {
                    // Parse depth data
                    self.order_books.bids = parse_depth(depth.buy, 'bid');
                    self.order_books.asks = parse_depth(depth.sell, 'ask');
                }
            );
        },

        getExchangeSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                self.symbols = mgr.getSymbols(3); // 3: exchange symbol
                const sidMap = {};
                if (mgr) {
                const self = this;
                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.allSymbols = arr; 
                self.syncQuotes();
            }
            }); 
        },
         syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const do_sync = function () {
                const sids = [];
                for (let sid in g_symbolMap) {
                    sids.push(sid);
                }

                if (sids.length > 0) {
                    // Read quotes from server
                    self.$http
                       .post('/api/v1/quotation/latest', {symbols: sids.join(',')})
                        .then((json) => {
                            const quotes = json.data;
                            if (quotes.length > 0) {
                                for (let i = 0; i < quotes.length; i++) {
                                    const quote = quotes[i];
                                    const sym = g_symbolMap[quote.id];
                                    if (sym) {
                                        sym.update(quote);
                                    }
                                }
                            }
                        })
                        .then(() => {
                            if (g_destoryed === false) {
                                // always restart timer
                                let delay = 4000 + Math.round(Math.random() * 3000);
                                g_quoteTimerId = setTimeout(do_sync, delay);
                            }
                        });
                }
            };

            do_sync();
        },

        showTrade: function (type) {
            this.$refs.createOrderPanel.setVisibleType(type);
            $('.trade-layout').addClass('skip-chart');
        },

        showChart: function () {
            $('.trade-layout').removeClass('skip-chart');
        },

        onPriceSelected: function (price) {
            this.$refs.createOrderPanel.setOrderPrice(price);
        },

        onOrderCreated: function () {
            this.$emit('order-created');
        }
    }
};
</script>
<style scoped>
.ipad-chart{
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 44px;
    margin: 0 15px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    display:none;
}
.skip-chart .ipad-chart{
    display: block;
}
.order-books .mod-body dl dd {
    position: initial;
    height: calc(100% - 30px)
}
.order-books .mod-body dl dd > div.asks {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.order-books .mod-body dl p {
    flex: inherit;
}
.asks ,.bids{
    height: calc(50% - 17px)
}
.order-books .mod-body dl dd > div {
    position: inherit;
}
.metadata-name{
    display: none;
}
.trade-layout .bg-content {
    background: rgb(14, 16, 21);
}
.side-border{
    display: none;
}
@media (min-width: 1440px) {
   .trading-left .dropdown-toggle{
        display: none;
    }
    .left-side{
        width: 330px;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #141517;
    }
    .trading-left .metadata-name{
        display: block;
    }
    .content-top > div.trading-left {
        width: calc(100% - 672px);
    }
    .page-top-nav li.active {
        border-bottom: 2px solid #0094ff;
    }
    .page-top-nav li.active a {
        color: #d7ddeb;
    }
    .page-top-nav {
        border-bottom: 1px solid rgba(33, 38, 50, .5);
    }
    .quotes-page .page-top-nav li.active a{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #111111;
    }
    .input-group{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 20px;
     }
    .input-group  .input-group-text,.input-group  .form-control{
        height: 30px;
        background: #1a1c24;
        border: 1px solid #1a1c24;
    }
    .input-group  .input-group-text{
        width: 40px;
        height: 30px;
        background: #1a1c24;
        border: 1px solid #1a1c24;
     }
     .input-group .form-control:focus{
        border: none;
     }
     .form-control{
        color: #7e8697;
     }
     .account{
        width: 100%;
        height: 200px;
        margin-top: 20px;
        background: #141517;
        border-top: 1px solid rgba(33, 38, 50, .5)
     }
     .account-title span{
        color: #d7ddeb;
        font-size: 16px;
        font-weight: 500;
        margin-left: 20px;
     }
     .account-title{
        height: 40px;
        display: flex;
        align-items: center;
     }
     .account-content{
        height: 50px;
        display: flex;
        margin-top: 10px;
     }
    .account-content >div{
        color: #d7ddeb;
        margin: auto;
        width: 77px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1a1c24;
        cursor: pointer;
     }
}
</style>