<template>
    <table class="table text-center">
        <thead>
            <tr class="text-label">
                <th class="hidden-sm">{{ $t('orders.label_create_time') }}</th>
                <th class="text-center">{{ $t('orders.label_amount') }}</th>
                <th class="text-right">{{ $t('orders.label_create_price') }}</th>
                <th class="text-right hidden-sm">{{ $t('orders.label_current_price') }}</th>
                <th class="text-right">{{ $t('orders.label_estimated_profit') }}</th>
                <th class="text-right">{{ $t('orders.label_countdown') }}</th>
            </tr>
        </thead>
        <tbody v-if="!open_orders">
            <tr>
                <td colspan="6">
                    <loading-indicator />
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-if="incomplete_orders.length === 0">
                <td colspan="6">
                    <div class="no-data-indicator m-5 text-content">
                        {{ $t('orders.no_orders') }}
                    </div>
                </td>
            </tr>
            <tr
                v-for="order in incomplete_orders"
                v-bind:key="order.metadata.orderId"
                class="text-content progress-bg"
                v-bind:style="'background-size: ' + order.progress + '% 100%'"
            >
                <td class="hidden-sm">{{ new Date(order.metadata.timeCreated).formatTime(true) }}</td>
                <td class="text-center">
                    {{ order.metadata.amount }}
                </td>
                <td class="text-right">
                    <span v-if="order.metadata.buyUp" class="color-up">
                        {{ order.metadata.createPrice }}
                        <i class="fa fa-arrow-up"></i>
                    </span>
                    <span v-else class="color-down">
                        {{ order.metadata.createPrice }}
                        <i class="fa fa-arrow-down"></i>
                    </span>
                </td>
                <td class="text-right hidden-sm">{{ symbol.price }}</td>
                <td v-bind:class="'text-right ' + (order.profit > 0 ? 'color-up' : 'color-down')">{{ order.profit }}</td>
                <td class="text-right">{{ order.countdown }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
function OpenOrderInfo(order) {
    this.metadata = Object.freeze(order);

    this.progress = 0;
    this.completed = false;
    this.countdown = '--';
    this.profit = '--';

    // Update progress for the order.
    this.updateProgress = function (diff_ms) {
        let remaining_ms = order.remainingSeconds * 1000 - diff_ms;

        if (remaining_ms > 0) {
            let seconds = (remaining_ms / 1000).toFixed(1);
            this.countdown = seconds + 'S';
            this.progress = 100 - Math.round(remaining_ms / order.duration) / 10;
        } else {
            this.progress = 100;
            this.countdown = '0S';
            this.completed = true;
        }

        return remaining_ms;
    };

    this.updateProfit = function (price, profit_map) {
        if (price > 0.00000001) {
            let profit;
            if (price === order.createPrice) {
                profit = 0;
            } else if ((price > order.createPrice && order.buyUp) || (price < order.createPrice && !order.buyUp)) {
                let profit_rate = profit_map[order.duration];
                profit = (order.amount * profit_rate) / 100;
            } else {
                profit = -order.amount;
            }

            this.profit = profit;
        } else {
            this.profit = '--';
        }
    };
}

// The id of the timer to update order progress.
let _progress_timer_id = 0;

// Indicates wether the component has been destroyed or not.
let _destroyed = true;

// The date and time when the open orders were loaded.
let _time_loaded = 0;

export default {
    props: ['symbol', 'boptionConfig'],

    data() {
        return { open_orders: null, profit_map: null };
    },

    computed: {
        incomplete_orders: function () {
            const output = [];
            if (this.open_orders) {
                for (let i = 0; i < this.open_orders.length; i++) {
                    const order = this.open_orders[i];
                    if (!order.completed) {
                        output.push(order);
                    }
                }
            }

            return output;
        }
    },

    created() {
        _destroyed = false;

        // Build a profit map for each duration
        const map = {};
        $(this.boptionConfig.durations).each((index, d) => {
            map[d.duration] = d.profit;
        });
        this.profit_map = Object.freeze(map);

        // Sync orders
        this.getOpenOrders();
    },

    beforeDestroy() {
        _destroyed = true;
        clearTimeout(_progress_timer_id);
    },

    methods: {
        getOpenOrders: function () {
            const self = this;
            $.callPostApi(self, '/api/v1/boptionorder/recentOpenOrders?id=' + encodeURIComponent(this.symbol.metadata.id)).then((json) => {
                if (json && json.errcode === 0) {
                    _time_loaded = new Date();

                    const output = [];
                    $(json.data).each((index, order) => {
                        output.push(new OpenOrderInfo(order));
                    });
                    self.open_orders = output;
                    self.updateProgress();
                }
            });
        },

        // Update order progress
        updateProgress: function () {
            const self = this;
            let completed = true;
            let sync_required = false;

            const diff = new Date() - _time_loaded;
            $(self.incomplete_orders).each((index, order) => {
                // update the progress of the order.
                if (order.updateProgress(diff) > 0) {
                    // Update profit for incomplete orders.
                    order.updateProfit(self.symbol.price_val, self.profit_map);
                    completed = false;
                } else {
                    sync_required = true;
                }
            });

            if (sync_required) {
                // Need to update balance when some order is completed.
                this.$emit('order-completed');
            }

            if (!completed && _destroyed === false) {
                // Update progress again in 200ms.
                setTimeout(() => {
                    self.updateProgress();
                }, 200);
            }
        }
    }
};
</script>
<style scoped>
.table > :not(caption) > * > * {
    background: #141517;
}
.no-data-indicator {
    color: #666;
    background: url("@/assets/coins/png/huobi/icon-empty.png") no-repeat top;
    background-size: auto 6rem;
}
td.text-right {
  width: 140px;
  max-width: 180px;
}
</style>