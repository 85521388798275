<template>
    <section class="page startup-page support-iframe">
        <!-- page title -->
        <div class="page-top">
        </div>
        <section class="pro_layout container">
            <!-- <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1> -->

            <ul class="page-top-nav mb-3">
                <!-- <li :class="{ active: !ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = false">{{ $t('startup.title_forecast') }}</a>
                </li> -->
                <li :class="{ active: ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = true">{{ $t('startup.title_ongoing') }}</a>
                </li>
                <li>
                    <router-link to="/startup/orders">{{ $t('startup.title_orders') }}</router-link>
                </li>
            </ul>

            <!-- Display a list of tokens -->
            <token-list :ongoing_tokens="ongoing_tokens" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>



<script>
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import TokenList from './Components/TokenList.vue';

export default {
    components: { ExtResourceIFrame, TokenList },

    data() {
        return {
            // Display on-going  or forecast tokens?
            ongoing_tokens: true
        };
    }
};
</script>
<style scoped>
.page-top{
    background: url("@/assets/images/juhb/subscription.png") no-repeat top;
    height: 280px;
    background-size: 100% 100%;
}
.page{
    background: #fff;
}
.page-top-nav li.active a {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #242428;
}
.page-top-nav li a {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #969699;
}
.page-top-nav{
    border-bottom: none;
}
@media (max-width: 768px){
    .page-top{
        height: 100px;
        background-size: 100% 100px;
    }
    .page-top-nav li{
        background: #E7E7E7;
        border-radius: 2px;
        margin-right: 10px;
    }
    .page-top-nav li a{
       font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000922;
    }
    .page-top-nav li.active {
        border-bottom: none;
        background: rgba(2,115,228,0.4);
        border-radius: 2px;
    }
     .page-top-nav li.active a{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #006CFF;
     }
}
</style>