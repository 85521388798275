<template>
    <div ref="createOrderModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" :class="{'buy-up':boptionOrder.buyUp,'buy-down':!boptionOrder.buyUp}">
                    <div class="modal-title" >{{ boptionOrder.buyUp ? $t('boption.label_buy_up') : $t('boption.label_buy_down') }} - {{ symbol.metadata.name }}</div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body" v-if="recentOrder">
                    <!-- Display the progress of the created order. -->
                    <order-progress-component :current_price="symbol.price_val" v-bind:order="recentOrder" @completed="onOrderCompleted" />
                </div>

                <!-- Order options -->
                <form v-else method="post" action="javascript:void(0)" @submit="createOrder">
                    <input type="hidden" name="BuyUp" v-model="boptionOrder.buyUp" />
                    <input type="hidden" name="Duration" v-model="boptionOrder.duration" />
                    <input type="hidden" name="SymbolId" :value="symbol.metadata.id" />
                    <input type="hidden" name="Amount" v-model="boptionOrder.amount" />

                    <div class="modal-body d-flex">
                        <dl class="col">
                            <dt class="text-label">{{ $t('boption.label_order_amount') }}</dt>
                            <dd class="text-content">{{ boptionOrder.amount }}</dd>
                        </dl>
                        <dl class="col">
                            <dt class="text-label">{{ $t('boption.label_order_duration') }} (S)</dt>
                            <dd class="text-content">{{ boptionOrder.duration }}</dd>
                        </dl>
                        <dl class="col">
                            <dt class="text-label">{{ $t('boption.label_order_profit_rate') }}</dt>
                            <dd class="color-down">{{ boptionOrder.profitRate }}</dd>
                        </dl>
                    </div>
                    <div class="modal-footer">
                        <div class="container-fluid">
                            <div class="row justify-content-end">
                                <div class="col col-md-auto">
                                    <button
                                        type="submit"
                                        class="btn btn-primary btn-block btn-lg"
                                        :class="{ 'btn-loading': submitting, 'bg-up': boptionOrder.buyUp, 'bg-down': !boptionOrder.buyUp }"
                                        :disabled="submitting">
                                        {{ boptionOrder.buyUp ? $t('boption.label_buy_up') : $t('boption.label_buy_down') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<style scoped>
label.coupon-option {
    font-size: 1.2rem;
    margin-top: 1.5rem;
}

.badge {
    font-size: 0.8rem;
}

@media (max-width: 1024px) {
    .modal {
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        height: auto;
    }

    .modal-dialog {
        margin: 0;
        max-width: 100% !important;
    }

    .modal-content {
        border-radius: 0.3rem 0.3rem 0 0;
        border: none;
    }
}
</style>

<script>
import OrderProgressComponent from './BOption_OrderProgress.vue';
import { Modal } from 'bootstrap';

export default {
    components: { OrderProgressComponent },

    props: ['symbol', 'boptionOrder'],

    data() {
        return {
            // Indicating whether the the order is submitting to the server or not.
            submitting: false,

            // The newly created order.
            recentOrder: null,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.createOrderModal, { dismissible: false });
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        showModal: function () {
            // Remove the existing order.
            this.recentOrder = null;

            // $(this.$refs.createOrderModal).modal('show');
            this.popupModal.show();
        },

        createOrder: function (e) {
            const frm = $(e.target);
            const data = frm.serialize();
            const self = this;

            self.submitting = true;
            $.callPostApi(self, '/api/v1/boption/create', data)
                .then((json) => {
                    if (json) {
                        if (json.errcode === 0) {
                            // Emit an event to indicate that a new order has been created.
                            self.$emit('order-created');

                            self.recentOrder = Object.freeze(json.data);
                        } else {
                            $.top_error(json.errmsg || self.$t('general.operation_error'));
                        }
                    }
                })
                .catch((err) => {
                    $.top_error(self.$t('general.operation_error'));
                })
                .then(() => {
                    self.submitting = false;
                });
        },

        onOrderCompleted: function (currency) {
            this.recentOrder = null;

            const self = this;
            setTimeout(() => {
                console.log(`## order-completed.`);
                self.$emit('order-completed', currency);

                // if (currency !== 'USDT') {
                //     self.getNumActiveCouponsAsync();
                // }
            }, 100);
        }
    }
};
</script>
<style scoped>
.modal-content {
    background-color: #fff;
    color: #000;
}
.buy-up {
    background: #00a171;
}
.buy-down{
    background:#e94359;
}
.modal-content .close span{
    font-size: 18px;
    color: #000;
    border: none;
}
.modal-title{
    color: #fff;
}
.trade-layout .modal .text-label {
    color: #000;
}
.trade-layout .modal .text-content {
    color: #000;
}
</style>