
// Remember the current UI version.
let g_ui_version = null;
let g_tzOffset = 540;

function ts_to_ms(ts) {
    // Convert UTC timestamp to milliseconds in +8:00 timezone.
    // return (ts + 28800) * 1000;
    return ts * 1000;
}


function toInterval(text) {
    const val = text * 1;
    if (!isNaN(val)) {
        return val >= 1440 ? Math.round(val / 1440) + 'D' : val;
    } else {
        return 1;
    }
}


/**
 * ================================================================================================
 * MyDataFeed was responsible for reading quotation data from our server.
 */
function MyDataFeed(sym) {
    if (!sym || !sym.metadata || typeof sym.metadata.id !== 'number') {
        throw new Error('Required a valid symbol object.');
    }

    this.symbol = sym;
    this.realtime_callback = null;
    this.reset_cache_callback = null;

    this.kline_type = 0;
    this.last_ts = 0; // Avoid updating old quotes
    this.resetBarsState();
}


MyDataFeed.prototype.resetBarsState = function (r) {
    // The total volume of the very last day
    this.last_day_volume = {
        day: 0,
        total_volume: 0
    };

    // The last displayed kline.
    this.last_kline = {
        time: 0,
        open: 0,
        close: 0,
        high: 0,
        low: 0,
        volume: 0
    };
};


/**
 * Convert an integer resolution to its string representation, e.g. 1, 2H, 1D
 * 
 * @param {int} r The resolution value.
 * @returns {string} The formated resolution, e.g. 2H, 1D.
 */
MyDataFeed.prototype.formatResolution = function (r) {
    return r < 1440 ? r + '' : r / 1440 + 'D';
};


/**
 * Convert an integer resolution to its string representation, e.g. 1, 2H, 1D
 * 
 * @param {string} resolution The formatted resolution.
 * @returns {int} An integer resolution.
 */
MyDataFeed.prototype.parseResolution = function (resolution) {
    let length = resolution.length;
    if (!length)
        throw new Error('Resolution must be specified.');

    let lastChar = resolution[length - 1];
    if (lastChar === 'D' || lastChar === 'd')
        return length === 1 ? 1440 : resolution.substring(0, length - 1) * 1440;

    return resolution * 1;
};


MyDataFeed.prototype.onReady = function (callback) {
    window.setTimeout(function () {
        callback({
            exchanges: [], // no need to display exchanges
            symbols_types: [], // no need to fiter symbols by symbol types
            supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '720', '1D', '1W', '1M'],
            supports_marks: true,
            supports_timescale_marks: true,
            supports_time: false // do not support reading server time
        });
    }, 0);
};


MyDataFeed.prototype.searchSymbols = function (userInput, exchange, symbolType, onResultReadyCallback) {
    console.log('searchSymbols: ' + userInput);

    let arr = [];
    const name = this.symbol.metadata.name;
    if (userInput === name) {
        arr.push({
            symbol: name,
            full_name: name, // e.g. BTCE:BTCUSD
            // description: ,
            exchange: 'Exchange',
            ticker: name, // optional
            type: 'index', // or 'futures' or 'bitcoin' or 'forex' or 'index'
            has_empty_bars: true
        });
    }

    onResultReadyCallback(arr);
};


MyDataFeed.prototype.resolveSymbol = function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    console.log('searchSymbols: ' + symbolName);

    const metadata = this.symbol.metadata;
    const name = metadata.name;
    // const supported_resolutions = metadata.type === 1
    //     ? ['1', '3', '5', '15', '30']
    //     : ['1', '3', '5', '15', '30', '60', '120', '240', '720', '1D', '1W', '1M'];
    const supported_resolutions = ['1', '3', '5', '15', '30', '60', '120', '240', '720', '1D', '1W', '1M'];

    const func = function () {
        if (symbolName === name) {
            const precision = Math.pow(10, metadata.pricePrecision);

            const symbol = {
                name: name,
                base_name: name,
                ticker: name,
                type: ['index'],
                description: '',
                session: '24x7', // 7 x 24 hours trade
                exchange: 'EX',
                timezone: 'Asia/Taipei',
                supported_resolutions: supported_resolutions,
                supprts_marks: true,
                supports_timescale_marks: true,
                data_status: 'streaming',
                has_intraday: true, // Need to support klines up to 1 minute.

                // This is an array containing intraday resolutions (in minutes) the server can really supports.
                // E.g., if the datafeed reported he supports resolutions ['1', '5', '15'], but in fact it has
                // only 1 minute bars for symbol X, it should set intraday_multipliers of X = [1].This will make
                // Charting Library to build 5 and 15 resolutions by itself.
                intraday_multipliers: ['1', '5', '15', '60', '240', '1D'],
                has_daily: true,                // We do support daily klines.
                // has_dwm: false, // seems not supported?
                has_weekly_and_monthly: false,  // We do NOT support weekly/monthly klines
                has_empty_bars: true,           // in case there is no data for some period.
                minmov: 1,
                pricescale: precision
                /*
                minmov: 1,
                pricescale: [1, 1, 100],
                minmov2: 0,
                */
                // has_seconds: true,           // Do not support klines for seconds.
                // seconds_multipliers: [30],

            };
            onSymbolResolvedCallback(symbol);
        }
    };
    window.setTimeout(func, 0);
};


/**
 * Charting Library calls this function when it is going to request some history data to give you an
 * ability to override required history depth. It passes some arguments so you could know how much
 * bars is it going to get.
 * 
 * @param {any} resolution Requested symbol's resolution
 * @param {any} resolutionBack Desired history period dimension. Supported values: D | M
 * @param {any} intervalBack Amount or resolutionBack periods which Library is going to request
 * @returns {any} History depth.
 */
MyDataFeed.prototype.calculateHistoryDepth = function (resolution, resolutionBack, intervalBack) {
    if (resolution === '1D') {
        // 2 years
        return { resolutionBack: 'M', intervalBack: 24 };
    } else {
        let val = resolution * 1;
        if (val === 1) {
            return { resolutionBack: 'D', intervalBack: 1 };
        } else if (!isNaN(val)) {
            // at most 1024 klines?
            let days = Math.ceil(val * 1024.0 / 60 / 24);
            return { resolutionBack: 'D', intervalBack: days };
        }
    }
};


/**
 * This function is called when chart needs a history fragment defined by dates range. The charting
 * library expects onHistoryCallback to be called JUST ONCE after receiving all the requesting
 * history. No further calls are expected.
 * 
 * @param {any} symbolInfo SymbolInfo object
 * @param {any} resolution string
 * @param {any} from unix timestamp, leftmost required bar time
 * @param {any} to unix timestamp, rightmost required bar time
 * @param {any} onHistoryCallback
 * function(array of bars, meta = {version = 2, noData = false}),
 * bar: object {time, close, open, high, low, volume},
 * meta: object {version = 2, noData = true | false, nextTime}
 * 
 * @param {any} onErrorCallback function(reason)
 * @param {any} firstDataRequest
 * boolean to identify the first history call for this symbol/resulution.
 * When it is true you can ignore to (which depends on browser's Date.now())
 * and return bars up to current bar (including it).
 */
MyDataFeed.prototype.getBars = function (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
    console.log('[' + symbolInfo.name + '] r=' + resolution + ', from=' + new Date(from * 1000).toISOString() + ', to=' + new Date(to * 1000).toISOString() + ' getBars called');
    const resolution_type = this.parseResolution(resolution);

    if (this.kline_type !== resolution_type) {
        this.kline_type = resolution_type;
        this.resetBarsState();
    }
    const feed = this;
    const sid = this.symbol.metadata.id;

    /**
     * The charting library expects onHistoryCallback to be called just once after receiving all the requesting history.
     * No further calls are expected.
     */
    const klines_arr = [];
    let max_ts = 0;

    // save the last kline which was read from server
    let last_raw_kline = null;

    function _readCompleted() {
        setTimeout(() => {
            try {
                // Append existing klines at the end
                if (resolution_type === 1 && g_initialKlines && g_initialKlines.length) {
                    max_ts = Math.max(from, max_ts + 1);
                    const filtered = g_initialKlines.filter(k => k.t >= max_ts && k.t <= to);
                    const output = [...filtered];
                    _convertKlinesData(output);

                    // Clear existing klines
                    g_initialKlines = null;
                }

                if (klines_arr.length === 0) {
                    // No data in this range.
                    onHistoryCallback([], { version: 2, noData: true });
                }
                else {
                    // last_raw_kline must have value
                    //
                    if (feed.last_kline.time /*ms*/ < last_raw_kline.t /* second */ * 1000) {
                        let day = new Date(ts_to_ms(last_raw_kline.t) + g_tzOffset * 60 * 1000).getUTCDate();
                        feed.last_day_volume = {
                            day: day,
                            total_volume: last_raw_kline.dv
                        };
                        feed.last_kline = klines_arr[klines_arr.length - 1];
                    }

                    onHistoryCallback(klines_arr, { version: 2, noData: true });
                }
            } catch (err) {
                console.error(`ERROR: ${err}`);
            }
        }, 0);
    }

    function _convertKlinesData(data) {
        const total_lines = data.length;
        for (let i = 0; i < total_lines; i++) {
            let item = data[i];

            // BUGFIX: item't indicates the creation time of the kline. But here we need to
            // align to the specified resolution. Otherwise, these kind of errors will be reported:
            //
            // time order violation, prev: Sat, 21 Mar 2020 18:13:16 GMT, cur: Sat, 21 Mar 2020 18:13:00 GMT
            //
            let ms = resolution_type >= 1440
                ? ts_to_ms(item.t + g_tzOffset * 60)
                : ts_to_ms(item.t - item.t % (resolution_type * 60));

            klines_arr.push({
                time: ms,
                open: item.s,
                close: item.e,
                high: item.max,
                low: item.min,
                volume: item.v
            });

            // Remember the max timestamp of all klines.
            max_ts = Math.max(max_ts, item.t);
        }

        // remember the last kline which was read from server
        let last = data[total_lines - 1];
        if (last_raw_kline === null || last_raw_kline.t < last.t) {
            last_raw_kline = last;
        }
    }

    function _beginReadCandles(fromTS, toTS) {
        // read klines from the server
        console.log('[' + symbolInfo.name + '] r=' + resolution + ', from=' + new Date(fromTS * 1000).toISOString() + ', to=' + new Date(toTS * 1000).toISOString() + ' begin read');
        let request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sid)}&type=${resolution_type}&from=${fromTS}&to=${toTS}&limit=1500`;
        $.ajax({
            xhrFields: { withCredentials: true },
            type: 'POST', url: request_url, success: function (data) {
                const total_lines = data.length;

                if (total_lines === 0) {
                    // completed. go ahead to render the charting library
                    console.log('No klines read. Stop reading more klines.');
                    _readCompleted();
                } else {
                    console.log(total_lines + ' klines read.');
                    _convertKlinesData(data);

                    if (total_lines < 200) { // by default server will return 200 klines at a time at most.
                        // already completed
                        _readCompleted();
                    } else {
                        // read more data from server
                        let nextTS = data[total_lines - 1].t + resolution_type * 60;
                        if (nextTS >= toTS) {
                            _readCompleted();
                        } else {
                            _beginReadCandles(nextTS, toTS);
                        }
                    }
                }
            },
            error: function (e) {
                console.log('Failed to get k-line data: ' + e);
                _readCompleted([]);
            }
        });
    }

    // Any gap before the existing 
    if (resolution_type === 1 && g_initialKlines && g_initialKlines.length) {
        let ts = g_initialKlines[0].t - 60; // until the previous minute of current klines
        if (from < ts) {
            _beginReadCandles(from, ts);
        } else {
            _readCompleted();
        }
    } else {
        _beginReadCandles(from, to);
    }
};


// This is called when a quotation data was received.
//
MyDataFeed.prototype.onQuoteData = function (data) {
    if (this.realtime_callback) {

        /*
         * The format of quotation data:
         * t: time
         * id: contract id
         * v: array, the items are:
         *      0. open
         *      1. close
         *      2. low
         *      3. high
         *      4. 24-hrs volume
         *      5. volume of today
         */
        const sid = this.symbol.metadata.id;
        if (data.id === sid && data.t > this.last_ts) {
            this.last_ts = data.t;
            // console.log(`## price = ${data.v[1]}`);

            // align to the specified resolution
            let resolution = this.kline_type;
            if (resolution >= 1440) {
                this.realtime_callback(
                    {
                        time: (data.t + g_tzOffset * 60) * 1000,
                        open: data.v[0],
                        close: data.v[1],
                        high: data.v[3],
                        low: data.v[2],
                        volume: data.v[5]
                    });
            } else {
                let milliseconds = ts_to_ms(data.t - data.t % (resolution * 60));
                let day = new Date(milliseconds + g_tzOffset * 60 * 1000).getUTCDate();

                let current_price = data.v[1];
                let current_vol = data.v[5];

                if (this.last_day_volume.day === day) {

                    let diff = Math.max(0, current_vol - this.last_day_volume.total_volume);
                    if (this.last_kline.time === milliseconds) {
                        this.last_kline.close = current_price;
                        this.last_kline.low = Math.min(this.last_kline.low, current_price);
                        this.last_kline.high = Math.max(this.last_kline.high, current_price);
                        this.last_kline.volume += diff;
                    } else {
                        this.last_kline = {
                            time: milliseconds,
                            open: current_price,
                            close: current_price,
                            high: current_price,
                            low: current_price,
                            volume: diff
                        };
                    }

                    // save the total volume of today
                    this.last_day_volume.total_volume = current_vol;
                } else {
                    // count volume from the zero.
                    this.last_day_volume = { day: day, total_volume: current_vol };
                    this.last_kline = {
                        time: milliseconds,
                        open: current_price,
                        close: current_price,
                        high: current_price,
                        low: current_price,
                        volume: current_vol
                    };
                }

                this.realtime_callback(this.last_kline);
            }
        }
    }
};


MyDataFeed.prototype.subscribeBars = function (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    console.log('subscribeBars: ' + symbolInfo.name + ', resolution=' + resolution + ', uuid=' + subscriberUID);

    this.realtime_callback = onRealtimeCallback;
    this.reset_cache_callback = onResetCacheNeededCallback;

    // // Start a socket.io connection
    // const sid = this.symbol.metadata.id;
    // let ns = this.symbol.metadata.type === 1 ? '/m' + sid : '/f' + sid;
    // $.initSocketIo(ns);
};


MyDataFeed.prototype.unsubscribeBars = function (uid) {
    console.log('unsubscribeBars: ' + uid);
    this.realtime_callback = null;
};


MyDataFeed.prototype.resetBars = function () {
    if (this.reset_cache_callback)
        this.reset_cache_callback();
};


/**
 * ================================================================================================
 * Saves state of a quote chart.
 */
const MyQuoteChart = {
    __state: function () {
        this._widget = null;
        this._position_line = null;
        this._last_order_id = null;
    },


    /**
     * Display a position line
     * @param {any} order_id The id of the order which will be displayed on the chart.
     * @param {any} price The creation price of the order.
     */
    set_position_line: function (order_id, price) {
        let state = MyQuoteChart.__state;
        if (state._widget) {
            if (!state._position_line) {
                // It's possible to have failure while create position lines when there were no quote data at all in the chart.
                //
                let line = null;
                try {
                    // create a new position line.
                    line = state._widget.chart().createPositionLine();
                } catch (err) {
                    console.error('Failed to create position line: ' + err);
                }

                if (line) {
                    line.setPrice(price)
                        .setQuantity(null)  // do not display order quanlity on the line
                        .setText(price);    // display price on the line
                    state._position_line = line;
                }
            } else {
                state._position_line.setPrice(price);
            }
            state._last_order_id = order_id;
        }
    },


    /**
     * Remove position line from the chart.
     * @param {any} order_id The id of the order which will be removed.
     */
    remove_position_line: function (order_id) {
        let state = MyQuoteChart.__state;
        let line = state._position_line;
        if (line && state._last_order_id === order_id) {
            state._position_line = null;
            line.remove();
        }
    }
};


/**
 * ================================================================================================
 * Initialize the tradingview chart.
 */

/**
 * Create a chart after the tradingview script is loaded.
 * @param {SymbolInfo} symbol A SymbolInfo object.
 */
function createChart(symbol, options) {
    // Initialize the data feed.
    const dataFeed = new MyDataFeed(symbol);
    const bigScreen = $(window).width() > 768;

    // Define all enabled features
    const features = [
        'adaptive_logo'
        , 'study_templates'
        , 'dont_show_boolean_study_arguments'
        , 'dont_show_boolean_study_arguments'
        , 'hide_last_na_study_output'
        , 'header_settings'
        , 'edit_buttons_in_legend'
        , 'format_button_in_legend'
        , 'header_fullscreen_button'
        , 'header_resolutions'
        , 'header_widget'
        , 'use_localstorage_for_settings'
        , 'save_chart_properties_to_local_storage'
    ];

    const localMap = {};
    localMap['ar'] = 'ar';
    localMap['cs'] = 'cs';
    // localMap['da_DK'] = 'da_DK';
    localMap['de'] = 'de';
    localMap['el'] = 'el';
    localMap['en'] = 'en';
    localMap['es'] = 'es';
    // localMap['et_EE'] = 'et_EE';
    localMap['fa'] = 'fa';
    localMap['fr'] = 'fr';
    // localMap['he_IL'] = 'he_IL';
    // localMap['hu_HU'] = 'hu_HU';
    // localMap['id_ID'] = 'id_ID';
    localMap['it'] = 'it';
    localMap['ja'] = 'ja';
    localMap['ko'] = 'ko';
    localMap['ms'] = 'ms_MY';
    // localMap['nl_NL'] = 'nl_NL';
    localMap['no'] = 'no';
    localMap['pl'] = 'pl';
    localMap['pt'] = 'pt';
    localMap['ro'] = 'ro';
    localMap['ru'] = 'ru';
    // localMap['sk_SK'] = 'sk_SK';
    localMap['sv'] = 'sv';
    localMap['th'] = 'th';
    localMap['tr'] = 'tr';
    localMap['vi'] = 'vi';
    localMap['zh'] = 'zh';
    localMap['zh-cht'] = 'zh_TW';
    const chart_locale = (typeof options.locale === 'string' ? localMap[options.locale.toLowerCase()] : null) || 'en';
    console.log(`## create chart with locale: ${options.locale}, actual=${chart_locale}`);

    // Only display the toolbar for non-binary-option symbols on big screens.
    if (bigScreen && symbol.metadata.type !== 1) {
        features.push('left_toolbar');
    }

    // Initialize the tradingview chart.
    // const is_version_41 = g_ui_version === '4.1';
    // const up_color = is_version_41 ? '#52B0A7' : '#0ECB81';
    // const down_color = is_version_41 ? '#EF8B7A' : '#F6465D';
    const up_color = '#58bd7d';
    const down_color = '#ef3123';

    let tz = 'Australia/Sydney';
    switch (options.region) {
        case 'kr': tz = 'Asia/Seoul'; break;
        case 'hk': tz = 'Asia/Hong_Kong'; break;
        case 'tw': tz = 'Asia/Taipei'; break;
        case 'de': tz = 'Africa/Cairo'; break;
    }

    const widgetOptions = {
        fullscreen: true,
        theme: 'dark', // use dark theme
        symbol: symbol.metadata.name,
        timezone: tz,
        container_id: 'tv_chart_container',
        //	BEWARE: no trailing slash is expected in feed URL
        datafeed: dataFeed,
        library_path: '/public/tv-charting-library/',
        custom_css_url: '/static/css/tv-chart.css',
        locale: chart_locale, // hard-coded to use zh-cn for now
        //	Regression Trend-related functionality is not implemented yet, so it's hidden for a while
        drawings_access: { type: 'black', tools: [{ name: 'Regression Trend' }] },
        disabled_features: [
            'volume_force_overlay' // dedicated area for volume
            // , 'property_pages'        // do not show propery pages on double clicks
            , 'header_screenshot'
            , 'header_symbol_search'
            , 'header_compare'
            , 'header_chart_type'
            // , 'header_undo_redo'
            // , 'header_fullscreen_button'
            // , 'header_interval_dialog_button'
        ],
        enabled_features: features,
        preset: 'mobile',
        overrides: {
            'paneProperties.legendProperties.showSeriesTitle': false, // do not display symbol name on left-top corner.
            'paneProperties.background': '#000',
            'paneProperties.vertGridProperties.color': '#000',
            'paneProperties.horzGridProperties.color': '#000',
            'symbolWatermarkProperties.transparency': 90,
            'scalesProperties.textColor': '#AAA',

            //	Candles styles
            'mainSeriesProperties.candleStyle.upColor': '#0ECB81',
            'mainSeriesProperties.candleStyle.downColor': '#F6465D',
            'mainSeriesProperties.candleStyle.drawWick': true,
            'mainSeriesProperties.candleStyle.drawBorder': true,
            'mainSeriesProperties.candleStyle.borderColor': '#378658',
            'mainSeriesProperties.candleStyle.borderUpColor': '#0ECB81',
            'mainSeriesProperties.candleStyle.borderDownColor': '#F6465D',
            'mainSeriesProperties.candleStyle.wickUpColor': '#0ECB81',
            'mainSeriesProperties.candleStyle.wickDownColor': '#F6465D',
            'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false
            /*
             //	Candles styles
            mainSeriesProperties.candleStyle.upColor: "#6ba583"
            mainSeriesProperties.candleStyle.downColor: "#d75442"
            mainSeriesProperties.candleStyle.drawWick: true
            mainSeriesProperties.candleStyle.drawBorder: true
            mainSeriesProperties.candleStyle.borderColor: "#378658"
            mainSeriesProperties.candleStyle.borderUpColor: "#225437"
            mainSeriesProperties.candleStyle.borderDownColor: "#5b1a13"
            mainSeriesProperties.candleStyle.wickUpColor: 'rgba( 115, 115, 117, 1)'
            mainSeriesProperties.candleStyle.wickDownColor: 'rgba( 115, 115, 117, 1)'
            mainSeriesProperties.candleStyle.barColorsOnPrevClose: false
            */
        },
        studies_overrides: {
            volumePaneSize: 'tiny',
            'volume.volume.color.0': '#F6465D',
            'volume.volume.color.1': '#0ECB81',
            'volume.volume ma.color': '#FF0000',
            // , 'volume.volume ma.transparency': 30
            'volume.volume ma.linewidth': 2,
            'volume.show ma': false,
            'volume.volume ma.plottype': 'line',
            'volume.volume ma.transparency': 1,
            'MACD.Histogram.color': '#e50370',
            'MACD.MACD.color': '#61863d',
            'MACD.Signal.color': '#e50370'
        },
        charts_storage_url: '/chart' + symbol.metadata.id,
        charts_storage_api_version: '1.1',
        client_id: 'web',
        user_id: 'dangdang',
        // interval: '1',  // The default interval
        interval: typeof options.defaultInterval === 'number' ? toInterval(options.defaultInterval) : '1'
    };

    const widget = new TradingView.widget(widgetOptions);

    // Save the widget reference
    g_dataFeed = dataFeed;
    g_widght = widget;

    // Save the widget reference to MyQuoteChart for future usage.
    // window.__tv_chart = MyQuoteChart.__state._widget = widget;
    widget.onChartReady(function () {
        let chart = widget.chart();

        var moving_avgs;
        if (bigScreen) {
            if (typeof g_moving_avgs !== 'undefined')
                moving_avgs = g_moving_avgs;
            else
                // default lines: MA5, MA10, MA30
                moving_avgs = { 5: '#34fafd', 10: '#e50370', 30: 'green' };
        } else {
            if (typeof g_moving_avgs_sm !== 'undefined')
                moving_avgs = g_moving_avgs_sm;
            else
                // default lines: MA10
                moving_avgs = { 10: '#e50370' };
        }

        for (let ma in moving_avgs) {
            let color = moving_avgs[ma];
            chart.createStudy('Moving Average', false, false, [ma, 'close', 0], null, {
                'Plot.linewidth': 2,
                'Plot.color': color
            });
        }

        if (bigScreen) {
            // only display MACD on big screens.
            chart.createStudy('MACD', false, false, [10, 30, 'open', 9]);
        }

        /*
        if (bigScreen) {
            // MA5
            chart.createStudy('Moving Average', false, false, [5, 'close', 0], null, {
                'Plot.linewidth': 2,
                'Plot.color': '#34fafd'
            });
        }
    
        // MA10
        chart.createStudy('Moving Average', false, false, [10, 'close', 0], null, {
            'Plot.linewidth': 2,
            'Plot.color': '#e50370'
        });
    
        if (bigScreen) {
            // MA30
            chart.createStudy('Moving Average', false, false, [30, 'close', 0], null, {
                'Plot.linewidth': 2,
                'Plot.color': 'green'
            });
    
            // only display MACD on big screens.
            chart.createStudy('MACD', false, false, [10, 30, 'open', 9]);
        }
        */
    });

    return widget;
}

let g_isScriptLoaded = false;
let g_symbol = null;
let g_widght = null;
let g_dataFeed = null;
let g_initialKlines = null;


/**
 * Update rtqs to the chart.
 * @param {quote} quote The quote data sent from the rtqs component.
 */
export function updateRtqsToChart(quote) {
    if (g_dataFeed) {
        g_dataFeed.onQuoteData(quote);
    }
};

/**
 * Initialize the chart.
 * @param {SymbolInfo} symbol A SymbolInfo object.
 * @param {*} options The options to create the chart.
 */
export function initializeChart(symbol, options) {
    g_symbol = symbol;
    g_ui_version = options.uiVersion;
    g_tzOffset = options.tzOffset || 540;

    // Make sure the script is loaded.
    if (g_isScriptLoaded) {
        createChart(symbol, options);
    } else {

        // Load the script chart here.
        console.log(`## load chart script`);
        let script = document.createElement('script');
        script.onload = (e) => {
            g_isScriptLoaded = true;

            // Do we need to create the chart? In case it needs time to load the tradingview script.
            if (g_symbol && g_symbol.metadata.id === symbol.metadata.id) {
                // Create the chart now
                createChart(symbol, options);
            }
        };
        script.async = true;
        script.src = '/public/tv-charting-library/charting_library.min.js?v=1.15';
        document.head.appendChild(script);
    }
}


/**
 * Destroy existing chart.
 */
export function destroyChart() {
    g_symbol = null;
    $.closeSocketIo();

    // Remove the chart from current page.
    g_dataFeed = null;
    if (g_widght) {
        g_widght.remove();
        g_widght = null;
    }
}


/**
 * Save the array of initial klines.
 * @param {Array} klines An array of klines.
 */
export function setInitialKlines(klines) {
    g_initialKlines = klines;
}


/**
 * Set timezone offset of the server.
 * @param {Number} offset The offset value in minutes.
 */
export function setTZOffset(offset) {
    if (!Number.isInteger(offset))
        throw new Error(`Invalid tz offset: ${offset}`);

    g_tzOffset = offset;
}
